import { GlobalIcon, useDragScroll } from "get-life-storybook-ts";
import { UniqueStatus } from "./Uniquestatus";
import { useContext, useState } from "react";
import { Stages } from "../constants/stages";
import { I18nContext } from "../../../../contexts/i18n.context";



interface StatusFilterI {
    filterByStage: (stage: string) => void
}

export const StatusFilter = ({filterByStage}: StatusFilterI) => {

    const {
        state: { translate },
      } = useContext(I18nContext);

    const [stageSelected, setStageSelected] = useState<string>("");
    const [ref] = useDragScroll();

    const handleStage = (stage: string) => {
        if (stage === stageSelected) {
            setStageSelected("")
            filterByStage("");
        } else {
            setStageSelected(stage)
            filterByStage(stage);
        }
        
    }


    return (
        <div className="flex flex-col w-full p-6 bg-white h-[278px] rounded-lg gap-10	">
            <div className="flex gap-2">
                <GlobalIcon iconName="ReportMoneyIcon" size="XS" color="#424242" />
                <p className="BodyL font-bold text-gray-text">{translate("projects.stage.title")}</p>
            </div>
            <div className="w-full flex justify-center gap-4 overflow-x-auto gradient-life5" ref={ref as any}>
            {
                Stages(translate).map((stage) => {
                    return (
                        <UniqueStatus
                            title={stage.title}
                            link1={translate("projects.stages.filter")}
                            status={stageSelected === stage.value ? "selected" : "default"}
                            className
                            badgeAtomAvatarIcon={stage.icon}
                            onClick={() => handleStage(stage.value)}
                        />
                    )
                })
            }
            </div>
            

        </div>
    );
}