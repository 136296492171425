/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useEffect } from "react";
import jwtDecode from "jwt-decode";
import { Route, Routes } from "react-router-dom";

import "./app.scss";
import DebugHistory from "./DebugHistory";

import { ContextGetlife } from "./contexts/ContextGetlife";

import Loader from "./views/components/Loader/Loader.component";
import Notification from "./views/components/Notification/Notification.component";

import PrivateRoute from "./Routes/PrivateRoute";
import { mapRoutes } from "./Routes/routes";
import PublicRoute from "./Routes/PublicRoutes";
import StatusPendingRoutes from "./Routes/StatusPendingRoutes";

import "@fontsource/merriweather/300.css";
import "@fontsource/merriweather/400.css";
import "@fontsource/merriweather/700.css";
import "@fontsource/merriweather/900.css";

const getRoutes = (routes) => {
  return routes.map((route) => (
    <Route exact path={route.path} element={route.component}>
      {route.children && getRoutes(route.children)}
    </Route>
  ));
};

function App() {
  
  const { token, status, rol, setRol } = useContext(ContextGetlife);

  useEffect(() => {
    try {
      const decoded = jwtDecode(token);
      setRol(decoded.roles);
    } catch (error) {
      setRol(false);
    }
  }, [token]);

  return (
    <div className="App">
      <main>
        <Loader />
        <Notification />
        <DebugHistory />

        <Routes>
          {getRoutes(mapRoutes.redirects)}
          {getRoutes(mapRoutes.publicRoutes)}
          <Route element={<PublicRoute token={token} />}>
            {getRoutes(mapRoutes.protectedRoutes)}
          </Route>
          <Route element={<PrivateRoute token={token} />}>
            <Route element={<StatusPendingRoutes status={status} />}>
              {getRoutes(mapRoutes.allowedRoutes(rol))}
            </Route>
          </Route>
        </Routes>
      </main>
    </div>
  );
}

export default App;
