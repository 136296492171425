/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";

import { loadStripe } from "@stripe/stripe-js";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import CardPayment from "./components/CardPayment";
import PaymentMethod from "./components/PaymentMethod";
import SepaPayment from "./components/SepaPayment";
import apiPaymentSetup from "./../../api/request/apiPaymentSetup";
import apiDownloadPdfTemplates from "../../api/request/apiDownloadPdfTemplates";
import apiLeadSendDocument from "../../api/request/apiLeadSendDocument";
import apiLeadPeriodUpdate from "../../api/request/apiLeadPeriodUpdate";
import "./css/_paymentMain.scss";
import ProductCard from "./components/ProductCard";
import { Elements } from "@stripe/react-stripe-js";
import { billingType } from "../../utils/staticData";
import CustomRadio from "../components/CustomRadio/CustomRadio";

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY, {
  locale: process.env.REACT_APP_DOMAIN_LOCALE,
  stripeAccount: process.env.REACT_APP_STRIPE_ACCOUNT,
});

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const PaymentMain = (props) => {
  const {
    brokerId,
    election,
    handlePaymentCard,
    handlePaymentSepa,
    undoSelectedPaymentMethod,
    leadId,
    paymentCard,
    paymentSepa,
    productCard,
    setLeadId,
    setShowModalCoupon,
    token,
    translate,
    broker,
    utm
  } = props;

  const DOMAIN = process.env.REACT_APP_DOMAIN_LOCALE;

  const [billingSelect, setBillingSelect] = useState("monthly");
  const [clientSecret, setClientSecret] = useState("");
  const [emailToSend, setEmailToSend] = useState("");
  const [paymentMethod, setPaymentMethod] = useState("");
  const [setupIntent, setSetupIntent] = useState([]);
  const [showModalSend, setShowModalSend] = useState(false);

  const handleModalSend = () => {
    setShowModalSend(!showModalSend);
  };

  const [sendEmail, setEmail] = useState(false);
  const [textModal, setTextModal] = useState("");
  const [colorModal, setColorModal] = useState("success");

  const handleSetupIntent = (data) => {
    setSetupIntent(data);
  };

  const handlePaymentMethod = (e) => {
    setPaymentMethod(e);
  };

  useEffect(() => {
    // const getClientSecret = () => {
    //   apiPaymentSetup(token, leadId).then((response) => {
    //     setClientSecret(response.clientSecret);
    //     setSetupIntent(response);
    //     setEmailToSend(response.email);
    //     setLeadId(leadId);
    //   });
    // };

    // getClientSecret();
  }, []);

  useEffect(() => {
    apiLeadPeriodUpdate(token, leadId, billingSelect).then((response) => {
      if (response === "OK") {
        setBillingSelect(billingSelect);
      }
    });
  }, []);

  const options = {
    // passing the client secret obtained from the server
    clientSecret: `${clientSecret}`,
  };

  let templates;

  if ((DOMAIN === "es" || DOMAIN === "pt" || DOMAIN === "it")) {
    templates = ["offer-w-base"];
    if (productCard.insuranceType === "premium") {
      templates = ["offer-w-base", "ipa-any-cause-exclusions"];
    }
    if (productCard.insuranceType === "eg") {
      templates = ["serious-diseases-exclusions"];
    }
  } else {
    templates = ["devis"]
  }

  const sendEmailDocument = async (email, text) => {
    const type = "general-a-exclusions";
    const resource = null;
    try {
      await apiLeadSendDocument(
        token,
        leadId,
        type,
        text,
        email,
        resource,
        templates
      );
      setShowModalSend(false);
      setTextModal(translate("notification.email.correct"));
      setColorModal("success");
      setEmail(true);
    } catch (e) {
      setTextModal(translate("notification.email.error"));
      setColorModal("error");
      setEmail(true);
    }
  };

  const downloadPDF = async () => {
    await apiDownloadPdfTemplates(leadId, templates).then((response) => {
      if (response.pdfUrl) {
        document
          .getElementById("downloadPDF")
          .setAttribute("href", response.pdfUrl);
        document.getElementById("downloadPDF").click();
      }
    });
  };

  const updatePeriod = async (period) => {
    await apiLeadPeriodUpdate(token, leadId, period).then((response) => {
      if (response === "OK") {
        setBillingSelect(period);
      }
    });
  };

  return (
    <div className="payment">
      <h1 className="payment--title">{translate("payment.title")}</h1>
      <section className="payment--section">
        <div className="payment--section__left">
          <ProductCard
            billingSelect={billingSelect}
            downloadPDF={downloadPDF}
            election={election}
            emailToSend={emailToSend}
            handleModalSend={handleModalSend}
            productCard={productCard}
            sendEmail={sendEmailDocument}
            setShowModalCoupon={setShowModalCoupon}
            setShowModalSend={setShowModalSend}
            showModalSend={showModalSend}
            translate={translate}
          />
        </div>
        {election && (
          <article className="payment--section__right">
            {(DOMAIN === "es" || DOMAIN === "fr" ) && (
              <div>
                <h3 className="title">
                  {translate("payment.billingtype.title")}
                </h3>
                <div className="radio">
                  <CustomRadio
                    answer={billingSelect}
                    toMap={billingType(translate)}
                    handleValue={updatePeriod}
                  />
                </div>
              </div>
            )}
            <PaymentMethod
              handlePaymentCard={handlePaymentCard}
              handlePaymentSepa={handlePaymentSepa}
              billingSelect={billingSelect}
              translate={translate}
            />
          </article>
        )}
        {(paymentCard || paymentSepa) && (
          <div className="payment--section__right">
            <Elements stripe={stripePromise}>
              <h3 className="title">{translate("payment.paymentMethod.title")}</h3>
              {paymentCard && (
                <CardPayment
                  options={options}
                  handleSetupIntent={handleSetupIntent}
                  setupIntent={setupIntent}
                  leadId={leadId}
                  token={token}
                  clientSecret={clientSecret}
                  handlePaymentMethod={handlePaymentMethod}
                  paymentMethod={paymentMethod}
                  brokerId={brokerId}
                  productCard={productCard}
                  translate={translate}
                  broker={broker}
                  utm={utm}
                  undoSelectedPaymentMethod={undoSelectedPaymentMethod}
                />
              )}
              {paymentSepa && (
                <SepaPayment
                  options={options}
                  handleSetupIntent={handleSetupIntent}
                  setupIntent={setupIntent}
                  leadId={leadId}
                  token={token}
                  clientSecret={clientSecret}
                  handlePaymentMethod={handlePaymentMethod}
                  paymentMethod={paymentMethod}
                  brokerId={brokerId}
                  productCard={productCard}
                  broker={broker}
                  translate={translate}
                  undoSelectedPaymentMethod={undoSelectedPaymentMethod}
                />
              )}
            </Elements>
          </div>
        )}
        <a
          href
          target="_blank"
          id="downloadPDF"
          style={{ display: "none" }}
        ></a>
      </section>
      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        open={sendEmail}
        autoHideDuration={1000}
        onClose={() => setEmail(false)}
      >
        <Alert
          onClose={() => setEmail(false)}
          severity={colorModal}
          sx={{ width: "100%" }}
        >
          {textModal}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default PaymentMain;
