/* eslint-disable react-hooks/exhaustive-deps */
// Vendors
import moment from "moment";
import { useContext, useState } from "react";
import { ContextGetlife } from "../../../contexts/ContextGetlife";
import { IconNameT } from "get-life-storybook-ts";

export const pagination_buttons_left: {icon: IconNameT, value: string}[] = [
  {
    icon: "ChevronsLeft",
    value: "first",
  },
  {
    icon: "ChevronLeft",
    value: "prev",
  }
];

export const pagination_buttons_right: {icon: IconNameT, value: string}[] = [
  {
    icon: "ChevronRight",
    value: "next",
  },
  {
    icon: "ChevronsRight",
    value: "last",
  },
];

export const select_range_values = (translate: (key: string) => string) => [
  { label: translate("tables.range.today"), value: 0 },
  { label: translate("tables.range.7days"), value: 7 },
  { label: translate("tables.range.15days"), value: 15 },
  { label: translate("tables.range.30days"), value: 30 },
  { label: translate("tables.range.3months"), value: 90 },
  { label: translate("tables.range.6months"), value: 180 },
  { label: translate("tables.range.lastYear"), value: 365 },
  { label: translate("tables.range.all"), value: -1 },
  { label: translate("tables.range.custom"), value: -2 },
];

interface TableInterface {
   
}

const TableLife5Controller = ({

}: TableInterface) => {
  const { setShowNotification, DOMAIN } = useContext(ContextGetlife);
  const endDateInit = moment().local().subtract(90, "day").format("YYYY-MM-DD");
  // Table
  const [totalItems, setTotalItems] = useState(0);
  const [indexPage, setIndexPage] = useState(1);
  const [pageSize, setPageSize] = useState(15);
  const [ordering, setOrdering] = useState(null);
  // DateRange
  const [endDate, setEndDate] = useState(endDateInit);
  const [startDate, setStartDate] = useState(
    moment().local().format("YYYY-MM-DD")
  );
  // OmniSearch
  const [omniValue, setOmniValue] = useState("");
  // Filter
  const [direction, setDirection] = useState("DESC");
  const [totalPages, setTotalPages] = useState(
    Math.ceil(totalItems / pageSize)
  );

  const filterByStage = (stage: string) => {
    console.log(stage);
}

  return {
    filterByStage,
    indexPage,
    omniValue,
    pageSize,
    setEndDate,
    setIndexPage,
    setOmniValue,
    setStartDate,
    totalItems,
    setTotalItems,
    totalPages,
  };
};

export default TableLife5Controller;
