const STATUSES_DATA = {
  "complete": { "overrideClass": "greeny", "translation": "Completado" },
  "prestack_pending": { "overrideClass": "greeny", "translation": "Cuestionario" },
  "product_questions_pending": { "overrideClass": "greeny", "translation": "Cuestionario" },
  "questions_pending": { "overrideClass": "greeny", "translation": "Cuestionario" },
  "personaldata_pending": { "overrideClass": "greeny", "translation": "Cuestionario" },
  "nationality_pending": { "translation": "Datos personales" },
  "rejected": { "overrideClass": "reddish", "translation": "Rechazado" },
  "upsell_pending": { "overrideClass": "orangish", "translation": "Pantalla de precios" },
  "paymentmethodpending_pending": { "overrideClass": "bluish", "translation": "Pendiente de pago" },
  "payment_pending": { "overrideClass": "bluish", "translation": "Pendiente de pago" },
  "address_pending": { "overrideClass": "bluish", "translation": "Pendiente de NIF/NIE" },
  "beneficiaries_pending": { "overrideClass": "bluish", "translation": "Pendiente de NIF/NIE" },
  "beneficiariestypes_pending": { "overrideClass": "bluish", "translation": "Pendiente de NIF/NIE" },
  "physicalperson_pending": { "overrideClass": "bluish", "translation": "Pendiente de NIF/NIE" },
  "bankingentity_pending": { "overrideClass": "bluish", "translation": "Pendiente de NIF/NIE" },
  "legalperson_pending": { "overrideClass": "bluish", "translation": "Pendiente de NIF/NIE" },
  "legalnote_pending": { "overrideClass": "reddish", "translation": "Pendiente notal legal" },
  "signature_pending": { "overrideClass": "browny", "translation": "Pendiente de firma" },
  "idcard_pending": { "overrideClass": "bluish", "translation": "Pendiente de NIF/NIE" },
  "ops_review": { "overrideClass": "greeny", "translation": "Completado" },
  "it_review": { "overrideClass": "greeny", "translation": "Completado" },
  "insurer_review": { "overrideClass": "greeny", "translation": "Completado" },
  "canceled": { "overrideClass": "reddish", "translation": "Cancelado" },
  "max_capital": { "overrideClass": "max_capital", "translation": "Max Capital"}
}
export const overrideStatusClass = (status) => {
  return STATUSES_DATA[status]
    ? STATUSES_DATA[status].overrideClass ?? ''
    : '';
}

export const getValidateStatus = (status, translate) => {
  return STATUSES_DATA[status]
    ? translate(`global.status.${status}`) ?? ''
    : '-';
};
