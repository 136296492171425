import * as React from "react";
import HttpBrokerRepository from "../../../../api/request/Broker/broker.service";
import BrokerUpdateRequest from "../../../../api/request/Broker/Model/Request/BrokerUpdateRequest.model";
import { ContextGetlife } from "../../../../contexts/ContextGetlife";
import { I18nContext } from "../../../../contexts/i18n.context";
import useForm from "../../../../hooks/useForm.hook";
import { requiredFieldsCompanyInfo } from "../../../../utils/staticData";
import { initialValuesFormCompany } from "../../../../utils/staticData";
import { DEFAULT_WORKING_HOURS } from "get-life-storybook-ts/lib/components/WorkingHours/WorkingHours";

const CompanyInformationController = () => {
  const {
    state: { translate },
  } = React.useContext(I18nContext);

  const { setLoading, setShowNotification, token, broker } =
    React.useContext(ContextGetlife);

  const brokerRepository = new HttpBrokerRepository("", token);

  const { error, handleSubmit, setValue, values, setValues } = useForm({
    fetch: (values: BrokerUpdateRequest) => fetchSubmit(values),
    setLoading: setLoading,
    requiredFields: requiredFieldsCompanyInfo,
  });
  const [originalValues, setOriginalValues] = React.useState<any>();
  const [uploadedFile, setUploadedFile] = React.useState<string>();
  const [fileError, setFileError] = React.useState<string>();
  const [hoursModal, setHoursModal] = React.useState(false);
  const [completedModal, setCompletedModal] = React.useState(false);
  const [tempWorkingHours, setTempWorkingHours] = React.useState(
    structuredClone(DEFAULT_WORKING_HOURS)
  );

  const saveTempWorkingHours = () => {
    setValue("workingHours", structuredClone(tempWorkingHours));
  };

  const fetchCompanyInfo = async () => {
    try {
      const fetchedValues = await brokerRepository.getCompanyInformation();
      if (!fetchedValues.themeColor) fetchedValues.themeColor = "";
      if (
        !fetchedValues.workingHours ||
        Array.isArray(fetchedValues.workingHours)
      )
        fetchedValues.workingHours = structuredClone(DEFAULT_WORKING_HOURS);
      setOriginalValues(structuredClone(fetchedValues));
      setValues(structuredClone(fetchedValues));
    } catch (error) {
      setOriginalValues(structuredClone(initialValuesFormCompany));
      setValues(structuredClone(initialValuesFormCompany));
    }
  };

  const undoChanges = () => setValues(structuredClone(originalValues));

  const fetchSubmit = async (values: BrokerUpdateRequest) => {
    const initialLogo = values.logo;
    values.logo = values.logo.search("data") !== -1 ? values.logo : "";
    try {
      await brokerRepository.updateBroker(values);
      values.logo = initialLogo;
      setLoading(false);
      setCompletedModal(true);
      if (broker?.landing) {
        setTimeout(() => {
          window.open(broker.landing, "_blank");
        }, 2000);
      }
    } catch (error: any) {
      let message = translate(`errors.companyInfo.${error.message}`);
      if (error.name === "TypeError") {
        message = translate(`errors.companyInfo.maximumBytes`);
      }
      setLoading(false);
      setShowNotification({
        message: message,
        status: true,
        type: "error",
        autoHideDuration: 10000,
      });
    }
  };

  React.useEffect(() => {
    (async () => {
      await fetchCompanyInfo();
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    if (values.workingHours && !Array.isArray(values.workingHours)) {
      setTempWorkingHours(structuredClone(values.workingHours));
    }
  }, [values.workingHours]);

  const fileInputElement = document.getElementById(
    "logoFileInput"
  ) as HTMLInputElement;

  const preventDefaults = (e: React.DragEvent) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const highlightFileInput = (e: React.DragEvent) => {
    const target = e.target as HTMLElement;
    target.classList.add("companyInfo-dragOver");
  };
  const unhighlightFileInput = (e: React.DragEvent) => {
    const target = e.target as HTMLElement;
    target.classList.remove("companyInfo-dragOver");
  };

  const handleOnDropImage = (e: React.DragEvent) => {
    let dt = e.dataTransfer;
    let files = dt.files;
    handleFile(files);
  };

  const handleFileInput = () => {
    if (fileInputElement.files) {
      handleFile(fileInputElement.files);
    }
  };

  const handleFile = (files: FileList) => {
    setFileError("");
    if (files.length >= 1) {
      const imageTypes = ["image/png", "image/jpeg"];
      const file = files[0];
      if (!imageTypes.includes(file.type)) {
        setFileError(translate("companyInformation.logo.error.format"));
        return;
      }
      let img = new Image();
      const imgUrl = URL.createObjectURL(file);
      img.src = imgUrl;
      img.onload = () => {
        const height = img.height;
        if (height > 140) {
          setFileError(translate("companyInformation.logo.error.size"));
          return;
        }
        let reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
          setUploadedFile(imgUrl);
          setValue("logo", reader.result);
        };
      };
    }
  };

  const handleRemoveLogo = () => {
    setFileError("");
    setUploadedFile(undefined);
    setValue("logo", "");
  };

  const triggerInputFile = () => {
    fileInputElement.click();
  };

  return {
    error,
    handleSubmit,
    setValue,
    values,
    translate,
    uploadedFile,
    preventDefaults,
    highlightFileInput,
    unhighlightFileInput,
    handleOnDropImage,
    triggerInputFile,
    handleFileInput,
    fileError,
    handleRemoveLogo,
    undoChanges,
    hoursModal,
    setHoursModal,
    tempWorkingHours,
    setTempWorkingHours,
    saveTempWorkingHours,
    completedModal,
    setCompletedModal,
  };
};

export default CompanyInformationController;
