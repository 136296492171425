import apiDownloadPdfTemplates from "./apiDownloadPdfTemplates";

export const getTemplates = (type) => {
  let templates = ["offer-w-base"];

  if (type === "DEVIS") templates = ["devis"];

  if (type === "premium") {
    templates = ["offer-w-base", "ipa-any-cause-exclusions"];
  }

  if (type === "eg") {
    templates = ["serious-diseases-exclusions"];
  }

  if (type === "legal-note") templates = ["legal-note"];

  if (type === "ipid") templates = ["ipid"];

  return templates;
};

const downloadPDF = async (leadId, insuranceType) => {
  const templates = getTemplates(insuranceType);
  return await apiDownloadPdfTemplates(leadId, templates).then((response) => {
    if (response.pdfUrl) return response.pdfUrl;
  });
};

export default async function apiGetDownloadableFiles(leadId, insuranceType) {
  const lang = process.env.REACT_APP_DOMAIN_LOCALE;
  const pdfs = [];

  const conditionsPdf = await downloadPDF(
    leadId,
    lang === "fr" ? "DEVIS" : insuranceType
  );
  if (conditionsPdf) {
    pdfs.push({
      label: "downloadableFiles.conditionsPdf",
      href: conditionsPdf,
    });
  }

  const notesPdf = await downloadPDF(leadId, "legal-note");
  if (notesPdf) {
    pdfs.push({
      label: "downloadableFiles.notesPdf",
      href: notesPdf,
    });
  }

  const IPID = { fr: true };
  if (IPID[lang]) {
    const IPIDPdf = await downloadPDF(leadId, "ipid");
    if (IPIDPdf) {
      pdfs.push({
        label: "downloadableFiles.ipidPdf",
        href: IPIDPdf,
      });
    }
  }

  return pdfs;
}
