// Vendors
import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
// Utils
import { ContextGetlife } from "../../../contexts/ContextGetlife";
import HttpLeadRepository from "../../../api/request/Lead/lead.service";
import { I18nContext } from "../../../contexts/i18n.context";
import moment from "moment";
import HttpListRepository from "../../../api/request/Lists/list.service";
import { ListProjectsV3 } from "../../../api/request/Lists/Model/Response/ListResponse.model";
import { GoogleContext } from "../../../contexts/GoogleTagManager.context";
import { GUARANTEE } from "../../../utils/staticData";
import { InsuranceType } from "../../../api/request/Lead/Model/Request/CarrierPricesRequest.model";
import { getTrackingUtms } from "../../../api/request/apiLeadTrackingGet";
import { extractFromString } from "../../../utils/extractFromString";
import { select_range_values } from "../../components/TableLife5/TableLife5.controller";
import { ViewProjectData } from "../../../api/request/Lead/Model/EditProjectReponse.model";

const ProjectController = () => {
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);
  const [viewModal, setViewModal] = useState(false);
  const [otpModal, setOtpModal] = useState(false);
  const [otpUrl, setOtpUrl] = useState("");
  const [modalData, setModalData] = useState({});
  const [viewData, setViewData] = useState<ViewProjectData | {}>({});
  const [projects, setProjects] = useState<ListProjectsV3[]>([]);
  const startDateInit = moment().local().subtract(90, "day").format("YYYY-MM-DD");
  // Table
  const [totalItems, setTotalItems] = useState(0);
  const [indexPage, setIndexPage] = useState(1);
  const [pageSize, setPageSize] = useState(15);
  const [savedStage, setSavedStage] = useState("")
  // DateRange
  const [endDate, setEndDate] = useState(moment().local().format("YYYY-MM-DD"));
  const [startDate, setStartDate] = useState(startDateInit);
  // OmniSearch
  const [omniValue, setOmniValue] = useState("");
  const [selectRangeDate, setSelectRangeDate] = useState(90);
  // Filter
  const [direction, setDirection] = useState("DESC");
  const [totalPages, setTotalPages] = useState(1);

  const [customDateModal, setCustomDateModal] = useState<boolean>(false);

  const {
    state: { translate },
  } = useContext(I18nContext);

  const {
    token,
    broker,
    brokerId,
    setLeadId,
    setShowNotification,
    setTokenVelogica,
  } = useContext(ContextGetlife);

  const rangeProps = {
    options: select_range_values(translate),
    placeholderDateRange: translate("projects.search.placeholderDateRange"),
    setEndDate: setEndDate,
    setIndexPage: setIndexPage,
    setStartDate: setStartDate,
    translate: translate
  };

  

  const listRepository = new HttpListRepository(token);
  const { handleTrackerQuestion } = useContext(GoogleContext);

  const leadRepository = new HttpLeadRepository(token);

  //--------------------------------------------------------------------------------------//
  //                                  ACTIONS / HANDLERS                                  //
  //--------------------------------------------------------------------------------------//

  const fetchProjects = async ({stage, startD, endD, omniSearch, newPage, newPageSize, signal}:{stage?: string, startD?: string, endD?: string, omniSearch?: string ,newPage?: number, newPageSize?: number, signal?: AbortSignal} = {}): Promise<void> => {
    try {
      const projectsData = await listRepository.getProjectList({
        indexPage: newPage ?? indexPage,
        omniValue: omniSearch ?? omniValue,
        pageSize: newPageSize ?? pageSize,
        startDate: startD ?? startDate,
        endDate: endD ?? endDate,
        stage: stage ?? savedStage,
        signal
      });

      setProjects(projectsData.items);
      setTotalItems(projectsData.totalItems)
      setIndexPage(projectsData.index);
      setPageSize(projectsData.size)
      setTotalPages(projectsData.totalPages)
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    const controller = new AbortController();
    const fetchProjectsSearch = async () => {
      fetchProjects({newPage: 1, signal: controller.signal})
    }

    fetchProjectsSearch();

    return () => {
      controller.abort({ reason: 409 });
    };
  }, [omniValue])

  useEffect(() => {
    const controller = new AbortController();
    const fetchProjectsPrices = async () => {
      const _projects = [...projects];

      const missingPrices = _projects.some(
        (project) => typeof project.annualPrice === "undefined"
      );
      if (missingPrices === false) return;

      let _aborted = false;

      await Promise.all(
        _projects.map(async (project, idx) => {
          await listRepository
            .getPriceByLead(project.leadId, controller.signal)
            .then((price) => {
              _projects[idx].annualPrice = price;
            })
            .catch((e) => {
              if (e?.reason === 409) _aborted = true;
              _projects[idx].annualPrice = {};
            });
        })
      );
      if (_aborted) return;
      setProjects(_projects);
    };

    fetchProjectsPrices();

    return () => {
      controller.abort({ reason: 409 });
    };
  }, [projects]);

  const filterByStage = (stage: string) => {
    setIndexPage(1)
    setSavedStage(stage);
    fetchProjects({stage: stage, newPage: 1});
  }

  const recoverProject = async (leadId: string, data: any, updateLead: boolean) => {
    const requestData: {capital: number, insuranceType: InsuranceType} = {
      capital: parseInt(data.capital),
      insuranceType: data.insuranceType,
    };

    try {
      if (updateLead) {
        await leadRepository.recoverProject({ leadId, data: requestData });
      }
      
      setLeadId(leadId);
      setTokenVelogica("");
      const utms = await getTrackingUtms(leadId);

      const event = "recoverLead";
      const eventData = {
        page: window.location.href,
        leadId: leadId,
        coverage: GUARANTEE[requestData.insuranceType!],
        intent: undefined,
        calculatedPremium: data.monthlyPrice,
        paymentMethod: undefined,
        calculatedCapital: data.capital,
        gender: data.gender,
        postalCode: data.postalCode,
        brokerEmployeeId: brokerId,
        brokerId: broker?.brokerageId,
        utm_source: utms.utm_source ?? "brokerapp",
        utm_medium: utms.utm_medium ?? broker?.brokerageId,
        utm_campaign: utms.utm_campaign ?? brokerId,
        utm_content: utms.utm_content,
        utm_term: utms.utm_term,
        utm_test: utms.utm_test,
        fbclid: utms.fbclid,
        gclid: utms.gclid,
        entryPage: utms.entryPage,
        referrerUrl: utms.referrerUrl,
        ABTestVersion: utms.ABTestVersion
      }

      const cookies = document.cookie;

      const VWOID = extractFromString(cookies, "_vwo_uuid_v2");
      const GAID = extractFromString(cookies, "_ga");
      const FullStoryID = extractFromString(cookies, "fs_uid");
      const AT_GD = extractFromString(cookies, "at_gd");

      const dataTracker = {
        leadUlid: leadId,
        campaign: "broker-projects",
        googleAnalyticsId: GAID,
        fullstoryId: FullStoryID,
        vwoId: VWOID,
        tid: null,
        atGd: AT_GD,
        utmCampaign: utms.utm_campaign,
        utmSource: utms.utm_source,
        utmMedium: utms.utm_medium && utms.utm_medium.toString(),
        utmContent: utms.utm_content,
        utmTerm: utms.utm_term,
        utmTest: utms.utm_test,
        gclid: utms.gclid,
        fbclid: utms.fbclid,
        entryPage: utms.entryPage,
        referrerUrl: utms.referrerUrl,
        aBTestVersion: utms.ABTestVersion
      }

      await leadRepository.saveTracking({ data: dataTracker });

      handleTrackerQuestion({
        type: "FS",
        event: event,
        data: {
          birthDate: new Date(data.birthdate),
          ...eventData
        }
      });
      handleTrackerQuestion({
        type: "GA",
        data: {
          event: event,
          birthDate: new Date(data.birthdate),
          ...eventData
        },
      });

      navigate("/questions");
    } catch (e: any) {
      setShowNotification({
        message: translate(`errors.projects.${e.message}`),
        status: true,
        type: "error",
        anchorOrigin: { vertical: "top", horizontal: "center" },
        link: (
          <a
            style={{ textDecoration: "none", color: "white" }}
            href="mailto:broker@life5.com"
          >
            {" "}
            broker@life5.com
          </a>
        ),
      });
    }
  };

  const handlePagination = (page: string) => {
    let newPage = 1;
    if (page === "next") {
      newPage = indexPage === projectsInfo.totalPages ? projectsInfo.totalPages : indexPage + 1;
      setIndexPage(newPage);
    } 
    if (page === "prev") {
      newPage = indexPage !== 1 ? (indexPage - 1) : 1
      setIndexPage(newPage)
    }

    if (page === "first") {
      newPage = 1
      setIndexPage(newPage)
    }

    if (page === "last") {
      newPage = projectsInfo.totalPages
      setIndexPage(newPage);
    }
    fetchProjects({newPage: newPage});
  }

  const handlePageSize = (value: number) => {
    setPageSize(value);
    fetchProjects({stage: savedStage, newPage: 1, newPageSize: value});
  }

  const projectsInfo = {
    translate: translate,
    projects: projects,
    totalItems: totalItems,
    filterByStage: filterByStage,
    pageSize: pageSize,
    handlePageSize: handlePageSize,
    handlePagination: handlePagination,
    indexPage: indexPage,
    totalPages: totalPages
  }

  useEffect(() => {
    fetchProjects({});
  }, [])

  useEffect(() => {
    let newStartDate = moment().local().subtract(selectRangeDate, "day").format("YYYY-MM-DD");
    let newEndDate = moment().local().format("YYYY-MM-DD");
    if (selectRangeDate === -1) {
      newStartDate = "2020-01-01";
    }
    if (selectRangeDate === -2) {
      setCustomDateModal(true);
      return;
    }
    
    setCustomDateModal(false);
    setStartDate(newStartDate);
    setEndDate(newEndDate);
    fetchProjects({startD: newStartDate, endD: newEndDate});
  }, [selectRangeDate])

  const fetchProjectsByCustomRange = ({
    start,
    end,
  }: {
    start: string;
    end: string;
  }) => {
    setStartDate(start);
    setEndDate(end);
    fetchProjects({ startD: start, endD: end });
  };

  return {
    projects,
    projectsInfo,
    broker,
    token,
    omniValue,
    setOmniValue,
    fetchProjects,
    showModal,
    setShowModal,
    viewModal,
    setViewModal,
    modalData,
    otpModal,
    otpUrl,
    setModalData,
    setOtpModal,
    setOtpUrl,
    recoverProject,
    rangeProps,
    selectRangeDate,
    setSelectRangeDate,
    viewData,
    setViewData,
    customDateModal,
    setCustomDateModal,
    fetchProjectsByCustomRange
  };
};

export default ProjectController;
