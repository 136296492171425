import { useContext, useEffect, useState } from "react";
import { ContextGetlife } from "../../../contexts/ContextGetlife";
import Question from "./Question";
import { I18nContext } from "../../../contexts/i18n.context";

const RenderQuestion = (props) => {
    const { brokerId, leadId, token, broker } = useContext(ContextGetlife);
    const {
        state: { translate },
    } = useContext(I18nContext);

    const [listQuestions, setListQuestions] = useState(props.questions);

    useEffect(() => (
        setListQuestions(props.questions)
    ), [props.questions])


    return (
        <>
            {listQuestions.map((question, index) => {
                return (
                    <Question
                        key={index}
                        answer={question.value}
                        answered={true}
                        answers={question.answers}
                        brokerId={brokerId}
                        broker={broker}
                        content={question.content}
                        default={question.value}
                        handleActualQuestion={props.handleActualQuestion}
                        handleIdEdited={props.handleIdEdited}
                        handleNextQuestion={props.handleNextQuestion}
                        isEditing={props.isEditing}
                        leadId={leadId}
                        placeholder={translate("questions.placeholder.select")}
                        questionId={question.questionId}
                        token={token}
                        type={question.type}
                        componentName={question?.componentName}
                        question={question}
                    />
                );
            })}
        </>
    )
}

export default RenderQuestion;