import { useEffect, useState } from "react";
import { useCloseByKey } from "../../utils/closeByKey.ts";
import KEYS from "../../utils/keyboardKeys";
import ButtonPrimary from "../components/ButtonPrimary/ButtonPrimary";
import ButtonSecondary from "../components/buttons/ButtonSecondary";
import Modal from "../Rebranding/organisms/Modal/Modal.component";
import TextArea from "../components/textArea/TextArea.js";

const InjectHTMLModal = ({
  handleSubmit,
  id,
  setOpen,
  open,
  translate,
  value,
}) => {
  const [customHTML, setCustomHTML] = useState(value);

  useEffect(() => {
    setCustomHTML(value);
  }, [value]);

  useCloseByKey({
    key: KEYS.KEY_ENTER,
    setClose: setOpen
  });

  useCloseByKey({ key: KEYS.KEY_ESC, setClose: setOpen });
  return (
    <Modal
      id={id}
      showModal={open}
      setShowModal={setOpen}
      ModalTitle={
        <h1 className="employees-modal-title">
          {translate("injectHTML.modal.title")}
        </h1>
      }
      ModalBody={
        <div className="employees-injection">
          <div className="employees-input">
            <TextArea
              handleValues={setCustomHTML}
              answer={customHTML}
              label="Custom HTML"
            />
          </div>
        </div>
      }
      ModalActions={
        <div className="employees-invitation-buttons">
          <ButtonSecondary
            content={translate("inviteEmployee.modal.buttonCancel")}
            className="upsell-personalization-add-button"
            handleChange={() => setOpen(false)}
          />
          <ButtonPrimary
            content={translate("injectHTML.modal.remove")}
            onClick={() => {
              setCustomHTML("");
            }}
          />
          <ButtonPrimary
            content={translate("injectHTML.modal.save")}
            onClick={() => handleSubmit(customHTML)}
          />
        </div>
      }
    />
  );
};

export default InjectHTMLModal;
