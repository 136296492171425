// Components
import { CustomTable } from "../components/CustomTable/custom-table.component";
import Button from "../Rebranding/atoms/Buttons/ButtonBase/Button.component";
// Controller
import EmployeesController from "./controllers/Employees.controller";
import EditEmployee from "./EditEmployee.component";
import "./Employees.scss";
import InjectHTMLModal from "./InjectHTMLModal";
import InvitationEmployee from "./InvitationModal.component";

const Employees = () => {
  const {
    error,
    handleSendInvitation,
    handleSubmit,
    setShowInvitation,
    setShowModal,
    setValue,
    showInvitation,
    showModal,
    tableProps,
    translate,
    values,
    showInjectionModal,
    setShowInjectionModal,
    handleSendInjection,
    customHTML
  } = EmployeesController();

  return (
    <div id="employee-page" className="employees__container">
      <CustomTable
        {...tableProps}
        componentButton={
          <div className="employees__buttons">
            <Button
              onClick={() => setShowInvitation(true)}
            >
              {translate("employees.button.invite")}
            </Button>
            <Button
              onClick={() => setShowInjectionModal(true)}
            >
              {translate("employees.button.inject")}
            </Button>
          </div>
        }
      />

      <InvitationEmployee
        showInvitation={showInvitation}
        id="email-invitation"
        setShowInvitation={setShowInvitation}
        handleSubmit={handleSendInvitation}
        translate={translate}
      />

      <InjectHTMLModal
        id="injection-modal"
        open={showInjectionModal}
        setOpen={setShowInjectionModal}
        handleSubmit={handleSendInjection}
        translate={translate}
        value={customHTML}
      />

      <EditEmployee
        id="employee-page"
        values={values}
        error={error}
        showModal={showModal}
        setValue={setValue}
        setShowModal={setShowModal}
        handleSubmit={handleSubmit}
        translate={translate}
      />
    </div>
  );
};

export default Employees;
