 interface Badge {
    atomAvatarTypeIconSizeClassName: string;
    atomAvatarIcon: JSX.Element;
 }
export const Badge = ({atomAvatarTypeIconSizeClassName, atomAvatarIcon} : Badge) => {
    return (
     <div className={`mt-auto min-h-[64px] min-w-[64px] h-[64px] w-[64px] rounded-full flex justify-center items-center ${atomAvatarTypeIconSizeClassName}`}>
        {atomAvatarIcon}
     </div>
    );
}