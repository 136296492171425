import React from "react";
import "./question.scss";
import { I18nContext } from "../../../contexts/i18n.context";
import { Typography } from "get-life-storybook-ts";

export default function QuestionAlert(props) {
  const {
    state: { translate },
  } = React.useContext(I18nContext);

  return (
    <div className="question-mainContainer">
      <div className="question-container">
        <Typography
          variant="BodyMedium"
          weight="700"
          className="question-questionalert"
        >
          <span
            dangerouslySetInnerHTML={{
              __html: props.telesubscription ? translate("questions.question.alerttelesub") : translate("questions.question.alert"),
            }}
          />
        </Typography>
      </div>
    </div>
  );
}
