import CompanyInformationController from "./CompanyInformation.controller";
import CardsBroker from "../../../CardsBroker/CardsBroker";
import {
  ButtonDS,
  GlobalIcon,
  InputDS,
  ModalDS,
  SelectDS,
  WorkingHours,
} from "get-life-storybook-ts";
import "./CompanyInformation.view.scss";
import React from "react";
import { errorMessage } from "../../../../utils/error";
import { DEFAULT_WORKING_HOURS } from "get-life-storybook-ts/lib/components/WorkingHours/WorkingHours";

const CompanyInformation: React.FC = () => {
  const {
    error,
    handleSubmit,
    setValue,
    values,
    translate,
    uploadedFile,
    preventDefaults,
    highlightFileInput,
    unhighlightFileInput,
    handleOnDropImage,
    triggerInputFile,
    handleFileInput,
    fileError,
    handleRemoveLogo,
    undoChanges,
    hoursModal,
    setHoursModal,
    saveTempWorkingHours,
    tempWorkingHours,
    setTempWorkingHours,
    completedModal,
    setCompletedModal,
  } = CompanyInformationController();

  const themeOptions = [
    {
      label: translate("companyInformation.purpleBlue"),
      value: "purpleBlue",
      color: "#423EF4",
    },
    {
      label: translate("companyInformation.darkBlue"),
      value: "darkBlue",
      color: "#00008F",
    },
    {
      label: translate("companyInformation.garnet"),
      value: "garnet",
      color: "#9C1830",
    },
    {
      label: translate("companyInformation.red"),
      value: "red",
      color: "#E52005",
    },
    {
      label: translate("companyInformation.orange"),
      value: "orange",
      color: "#FF6101",
    },
    {
      label: translate("companyInformation.darkGreen"),
      value: "darkGreen",
      color: "#036100",
    },
    {
      label: translate("companyInformation.lightGreen"),
      value: "lightGreen",
      color: "#0F8402",
    },
  ];

  const themeSelected = themeOptions.find(
    (t) => t.value === values.themeColor
  )?.color;

  return (
    <div className="companyInfo-grid">
      <div>
        <h2 className="H3 text-[#424242] font-bold font-DM">
          {translate("companyInformation.title")}
        </h2>
        <p className="BodyM text-[#424242]">
          {translate("companyInformation.description")}
        </p>

        <div className="mt-[40px] p-[24px_24px_32px] flex flex-col gap-[32px] bg-white rounded-lg">
          <div className="flex flex-row gap-[16px] items-center text-[#424242] BodyL font-bold">
            <GlobalIcon iconName="BuildingIcon" color="currentColor" />
            <span>{translate("companyInformation.info.title")}</span>
          </div>

          <div
            className="bg-[#F5F7FA] rounded-lg p-[24px_24px_32px] flex flex-col gap-[24px]"
            style={{
              boxShadow:
                "0px 1px 3px 0px rgba(0, 0, 0, 0.10), 0px 2px 1px 0px rgba(0, 0, 0, 0.06), 0px 1px 1px 0px rgba(0, 0, 0, 0.07);",
            }}
          >
            <h3 className="BodyM text-[#424242] font-bold">
              {translate("companyInformation.brokerage.title")}
            </h3>

            <div className="grid md:grid-cols-2 gap-x-[48px] gap-y-[24px]">
              <InputDS
                label={translate("companyInformation.companyName.label")}
                placeholder={translate(
                  "companyInformation.companyName.palceholder"
                )}
                icon="BuildingArchIcon"
                value={values.name}
                error={errorMessage(error, "name")}
                onChange={(e) => setValue("name", e.target.value)}
              />
              <InputDS
                label={translate("companyInformation.business.label")}
                placeholder={translate(
                  "companyInformation.business.palceholder"
                )}
                icon="BuildingIcon"
                value={values.businessName}
                error={errorMessage(error, "businessName")}
                onChange={(e) => setValue("businessName", e.target.value)}
              />
              <InputDS
                label={translate("companyInformation.nif.label")}
                placeholder={translate("companyInformation.nif.palceholder")}
                icon="IdCardIcon"
                value={values.idCard}
                error={errorMessage(error, "idCard")}
                onChange={(e) => setValue("idCard", e.target.value)}
              />
              <InputDS
                label={translate("companyInformation.autorisation.label")}
                placeholder={translate(
                  "companyInformation.autorisation.palceholder"
                )}
                icon="FileCertificateIcon"
                value={values.idDgsfp}
                error={errorMessage(error, "idDgsfp")}
                onChange={(e) => setValue("idDgsfp", e.target.value)}
              />
            </div>
            <InputDS
              label={translate("companyInformation.address.label")}
              placeholder={translate("companyInformation.address.palceholder")}
              icon="MapPinIcon"
              value={values.address}
              error={errorMessage(error, "address")}
              onChange={(e) => setValue("address", e.target.value)}
            />
            <div className="flex flex-row items-start gap-[4px] BodyS text-[#8E8E8E]">
              <GlobalIcon iconName="InfoIcon" size="XXS" color="currentColor" />
              <span className="flex-1">
                {translate("companyInformation.brokerage.description")}
              </span>
            </div>
          </div>

          <div
            className="bg-[#F5F7FA] rounded-lg p-[24px_24px_32px] flex flex-col gap-[24px]"
            style={{
              boxShadow:
                "0px 1px 3px 0px rgba(0, 0, 0, 0.10), 0px 2px 1px 0px rgba(0, 0, 0, 0.06), 0px 1px 1px 0px rgba(0, 0, 0, 0.07);",
            }}
          >
            <h3 className="BodyM text-[#424242] font-bold">
              {translate("companyInformation.personalisation.title")}
            </h3>

            <div
              className="grid md:grid-cols-2 gap-x-[48px] gap-y-[24px]"
              style={{ "--iconColor": themeSelected } as React.CSSProperties}
            >
              <SelectDS
                label={translate(
                  "companyInformation.personalisation.color.title"
                )}
                placeholder={translate(
                  "companyInformation.personalisation.color.description"
                )}
                icon={themeSelected ? "PentagonIcon" : "PaletteIcon"}
                options={themeOptions}
                helper={translate(
                  "companyInformation.personalisation.color.helper"
                )}
                onChange={(e) => setValue("themeColor", e.target.value)}
                value={values.themeColor}
                className="companyInfo-themeSelector"
              />
              <div className="flex flex-col gap-[12px]">
                <span className="BodyL text-[#424242]">
                  {translate("companyInformation.personalisation.logo.label")}
                </span>
                {values.logo || uploadedFile ? (
                  <picture className="h-[120px] p-[16px] box-content">
                    <img
                      src={values.logo || uploadedFile}
                      alt=""
                      className="object-contain object-center w-full h-full"
                    />
                  </picture>
                ) : (
                  <div
                    className="border-dashed border-[2px] border-[var(--primary-color)] rounded-lg p-[16px] bg-[#ECF0FF] cursor-pointer [&>*]:pointer-events-none transition-all"
                    onDragEnter={(e) => {
                      preventDefaults(e);
                      highlightFileInput(e);
                    }}
                    onDragLeave={(e) => {
                      preventDefaults(e);
                      unhighlightFileInput(e);
                    }}
                    onDragOver={(e) => {
                      highlightFileInput(e);
                      preventDefaults(e);
                    }}
                    onDrop={(e) => {
                      preventDefaults(e);
                      unhighlightFileInput(e);
                      handleOnDropImage(e);
                    }}
                    onClick={triggerInputFile}
                  >
                    <div className="BodyL font-bold text-[#424242]">
                      {translate(
                        "companyInformation.personalisation.logo.instruction"
                      )}
                    </div>
                    <div
                      className="BodyM text-[#424242] mt-[32px]"
                      dangerouslySetInnerHTML={{
                        __html: translate(
                          "companyInformation.personalisation.logo.format"
                        ),
                      }}
                    />
                  </div>
                )}
                <input
                  type="file"
                  id="logoFileInput"
                  accept="image/jpeg, image/png"
                  onChange={handleFileInput}
                  className="hidden"
                />
                {fileError ? (
                  <div className="text-[#EA5F5F] BodyS flex flex-row gap-[8px]">
                    <GlobalIcon
                      iconName="AlertCircleIcon"
                      color="currentColor"
                      size="XXS"
                    />
                    <span>{fileError}</span>
                  </div>
                ) : null}
                <div className="flex flex-row flex-wrap gap-[16px] justify-end">
                  {values.logo || uploadedFile ? (
                    <>
                      <ButtonDS
                        label={translate(
                          "companyInformation.logo.button.delete"
                        )}
                        leftIcon="TrashIcon"
                        buttonType="secondary"
                        size="32"
                        className="w-fit"
                        onClick={handleRemoveLogo}
                      />
                      <ButtonDS
                        label={translate("companyInformation.logo.button.edit")}
                        leftIcon="PencilIcon"
                        buttonType="tertiary"
                        size="32"
                        className="w-fit"
                        onClick={triggerInputFile}
                      />
                    </>
                  ) : (
                    <ButtonDS
                      label={translate("companyInformation.logo.button.upload")}
                      leftIcon="FileDownload"
                      buttonType="tertiary"
                      size="32"
                      className="w-fit"
                      onClick={triggerInputFile}
                    />
                  )}
                </div>
              </div>
            </div>
            <div className="flex flex-row items-start gap-[4px] BodyS text-[#8E8E8E] mt-[8px]">
              <GlobalIcon iconName="InfoIcon" size="XXS" color="currentColor" />
              <span className="flex-1">
                {translate("companyInformation.personalisation.description")}
              </span>
            </div>
          </div>

          <div
            className="bg-[#F5F7FA] rounded-lg p-[24px_24px_32px] flex flex-col gap-[24px]"
            style={{
              boxShadow:
                "0px 1px 3px 0px rgba(0, 0, 0, 0.10), 0px 2px 1px 0px rgba(0, 0, 0, 0.06), 0px 1px 1px 0px rgba(0, 0, 0, 0.07);",
            }}
          >
            <h3 className="BodyM text-[#424242] font-bold">
              {translate("companyInformation.contact.title")}
            </h3>

            <div className="grid md:grid-cols-2 gap-x-[48px] gap-y-[24px]">
              <div className="flex flex-col gap-[24px]">
                <InputDS
                  label={translate("companyInformation.brokerName.label")}
                  placeholder={translate(
                    "companyInformation.brokerName.palceholder"
                  )}
                  icon="SimpleUserIcon"
                  value={values.contactName}
                  error={errorMessage(error, "contactName")}
                  onChange={(e) => setValue("contactName", e.target.value)}
                />
                <InputDS
                  label={translate("companyInformation.brokerPhone.label")}
                  placeholder={translate(
                    "companyInformation.brokerPhone.palceholder"
                  )}
                  icon="DeviceMobile"
                  type="number"
                  value={values.contactPhone}
                  error={errorMessage(error, "contactPhone")}
                  onChange={(e) => setValue("contactPhone", e.target.value)}
                />
                <InputDS
                  label={translate("companyInformation.brokerEmail.label")}
                  placeholder={translate(
                    "companyInformation.brokerEmail.palceholder"
                  )}
                  icon="MailIcon"
                  value={values.contactEmail}
                  error={errorMessage(error, "contactEmail")}
                  onChange={(e) => setValue("contactEmail", e.target.value)}
                />
              </div>
              {values.workingHours ? (
                <div>
                  <div className="BodyL text-[#424242] mb-[12px]">
                    {translate("companyInformation.brokerSchedule.label")}
                  </div>
                  <div className="grid grid-cols-[1fr_1fr] gap-x-[24px] gap-y-[8px] text-[#424242] BodyM">
                    {Object.keys(Object.assign({}, DEFAULT_WORKING_HOURS)).map(
                      (day, idx) => {
                        const hours = values.workingHours[day];
                        const iterableLength = hours.length / 2;
                        return (
                          <React.Fragment key={`${day}_${idx}`}>
                            <span>
                              {translate(`companyInformation.${day}`)}
                            </span>
                            {hours.length > 0 ? (
                              <div>
                                {Array.from({ length: iterableLength }).map(
                                  (_, idy) => {
                                    const openIdx = idy * 2;
                                    const closeIdx = idy * 2 + 1;
                                    return (
                                      <div key={`${day}_${idx}_${idy}`}>
                                        {hours[openIdx]} - {hours[closeIdx]}
                                      </div>
                                    );
                                  }
                                )}
                              </div>
                            ) : (
                              <span>
                                {translate("companyInformation.closed")}
                              </span>
                            )}
                          </React.Fragment>
                        );
                      }
                    )}
                  </div>
                  <ButtonDS
                    label={translate(
                      "companyInformation.brokerSchedule.modify"
                    )}
                    leftIcon="PenIcon"
                    buttonType="tertiary"
                    size="32"
                    className="ml-auto w-fit mt-[12px]"
                    onClick={() => setHoursModal(true)}
                  />
                  <ModalDS
                    open={hoursModal}
                    onClose={() => setHoursModal(false)}
                    title={translate("companyInformation.brokerSchedule.label")}
                    icon="CalendarEventIcon"
                    content={
                      <WorkingHours
                        daysTranslations={{
                          MONDAY: translate("companyInformation.MONDAY"),
                          TUESDAY: translate("companyInformation.TUESDAY"),
                          WEDNESDAY: translate("companyInformation.WEDNESDAY"),
                          THURSDAY: translate("companyInformation.THURSDAY"),
                          FRIDAY: translate("companyInformation.FRIDAY"),
                          SATURDAY: translate("companyInformation.SATURDAY"),
                          SUNDAY: translate("companyInformation.SUNDAY"),
                        }}
                        hours={tempWorkingHours}
                        setHours={setTempWorkingHours}
                      />
                    }
                    buttons={
                      <div className="flex flex-row gap-[16px]">
                        <ButtonDS
                          label={translate(
                            "companyInformation.brokerSchedule.cancel"
                          )}
                          buttonType="secondary"
                          className="flex-1"
                          onClick={() => setHoursModal(false)}
                        />
                        <ButtonDS
                          label={translate(
                            "companyInformation.brokerSchedule.save"
                          )}
                          buttonType="primary"
                          className="flex-1"
                          onClick={() => {
                            saveTempWorkingHours();
                            setHoursModal(false);
                          }}
                        />
                      </div>
                    }
                  />
                </div>
              ) : null}
            </div>
            <div className="flex flex-row items-start gap-[4px] BodyS text-[#8E8E8E]">
              <GlobalIcon iconName="InfoIcon" size="XXS" color="currentColor" />
              <span className="flex-1">
                {translate("companyInformation.contact.description")}
              </span>
            </div>
          </div>

          <div className="flex flex-row gap-[16px] justify-end">
            <ButtonDS
              label="Cancelar"
              buttonType="secondary"
              onClick={undoChanges}
            />
            <ButtonDS
              label="Guardar y ver cambios"
              buttonType="primary"
              onClick={handleSubmit}
            />
          </div>
        </div>
      </div>
      <CardsBroker />
      <ModalDS
        open={completedModal}
        onClose={() => setCompletedModal(false)}
        title={translate("companyInformation.saveCompleted.title")}
        icon="SettingsIcon"
        content={
          <div>
            <div className="bg-[#ECECFE] p-[8px] rounded-full w-fit mb-[16px]">
              <GlobalIcon
                iconName="CheckedIcon"
                size="XS"
                color="var(--primary-color)"
              />
            </div>
            <p
              className="text-[#424242] BodyM"
              dangerouslySetInnerHTML={{
                __html: translate(
                  "companyInformation.saveCompleted.description"
                ),
              }}
            />
          </div>
        }
      />
    </div>
  );
};

export default CompanyInformation;
