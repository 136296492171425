import { ButtonDS, Chip, ModalDS } from "get-life-storybook-ts";
import { useContext } from "react";
import { I18nContext } from "../../contexts/i18n.context";
interface ViewProjectI {
  otpUrl: string;
  otpModal: boolean;
  setOtpModal: (value: boolean) => void;
}

const OtpModal = ({ otpUrl, otpModal, setOtpModal }: ViewProjectI) => {
  const {
    state: { translate },
  } = useContext(I18nContext);



  const onClose = () => {
    setOtpModal(false);
  };

  return (
    <>
      <ModalDS
        open={otpModal}
        onClose={onClose}
        icon="LinkToIcon"
        title={translate("modal.otp.title")}
        content={
          <div>
            <div className="py-[16px] flex flex-col border-b border-[#E2E2E2]">
              <div className="pl-[24px] flex flex-col">
                <span className="BodyS text-[#7B7B7B] font-medium" dangerouslySetInnerHTML={{__html: translate("modal.otp.description")}}>
                </span>
                
                <span className="pb-[16px] BodyM text-[#424242] font-medium">
                  {otpUrl}
                </span>
              </div>
            </div>
          </div>
        }
      />
    </>
  );
};

export default OtpModal;
