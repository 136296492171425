import { useContext, useRef, useState } from "react";
import { I18nContext } from "../../contexts/i18n.context";
import {
  ButtonDS,
  CardInfo,
  CustomModal,
  GlobalIcon,
  IconButton,
} from "get-life-storybook-ts";
import { ContextGetlife } from "../../contexts/ContextGetlife";
import "./CardsBroker.scss";

const CardsBroker = () => {
  const { broker } = useContext(ContextGetlife);
  const {
    state: { translate },
  } = useContext(I18nContext);
  const DOMAIN = process.env.REACT_APP_DOMAIN_LOCALE;
  const doubtModal = useRef();
  const shareModal = useRef();

  const [doubt, setDoubt] = useState<
    | {
        label: string;
        body: string;
        href: string;
      }
    | undefined
  >();
  const [linkCopied, setLinkCopied] = useState(false);

  const doubtsData = [
    {
      label: translate("dashboard.infoCards.doubts.doubt1.title"),
      body: translate("dashboard.infoCards.doubts.doubt1.body"),
      href: translate("dashboard.infoCards.doubts.doubt1.href"),
    },

    {
      label: translate("dashboard.infoCards.doubts.doubt2.title"),
      body: translate("dashboard.infoCards.doubts.doubt2.body"),
      href: translate("dashboard.infoCards.doubts.doubt2.href"),
    },

    {
      label: translate("dashboard.infoCards.doubts.doubt3.title"),
      body: translate("dashboard.infoCards.doubts.doubt3.body"),
      href: translate("dashboard.infoCards.doubts.doubt3.href"),
    },

    {
      label: translate("dashboard.infoCards.doubts.doubt4.title"),
      body: translate("dashboard.infoCards.doubts.doubt4.body"),
      href: translate("dashboard.infoCards.doubts.doubt4.href"),
    },
  ];

  const handleDoubtModal = (idx: any) => {
    setDoubt(idx);
    // @ts-ignore
    doubtModal.current?.handleShowPortal();
  };

  const handleShareModal = () => {
    // @ts-ignore
    shareModal.current?.handleShowPortal();
  };

  return (
    <div className="cardsBroker-cardsInfo">
      {DOMAIN === "es" || DOMAIN === "pt" ? (
        <CardInfo
          icon="SpeakerphoneIcon"
          title={translate("dashboard.infoCards.share.title")}
          body={translate("dashboard.infoCards.share.body")}
          buttonPrimary={{
            label: translate("dashboard.infoCards.share.shareButton"),
            leftIcon: "ShareIcon",
            onClick: handleShareModal,
            size: "32",
          }}
          buttonSecondary={{
            label: translate("dashboard.infoCards.share.previewButton"),
            leftIcon: "WorldIcon",
            href: broker ? broker.landing : "",
            target: "_blank",
            size: "32",
          }}
        />
      ) : null}
      <CardInfo
        title={translate("dashboard.infoCards.doubts.title")}
        body={translate("dashboard.infoCards.doubts.body")}
        options={doubtsData.map((d, idx) => {
          const { label } = d;
          return {
            label,
            onClick: () => handleDoubtModal(doubtsData[idx]),
          };
        })}
      />
      <CardInfo
        icon="QuestionMark"
        title={translate("dashboard.infoCards.contact.title")}
        body={translate("dashboard.infoCards.contact.body")}
        options={[
          {
            label: translate("dashboard.infoCards.contact.mail"),
            href: translate("dashboard.infoCards.contact.mailHref"),
          },
          {
            label: translate("dashboard.infoCards.contact.phone"),
            href: translate("dashboard.infoCards.contact.phoneHref"),
          },
        ]}
        buttonPrimary={{
          label: translate("dashboard.infoCards.contact.contactButton"),
          leftIcon: "WhatsappIcon",
          size: "32",
          href: translate("dashboard.infoCards.contact.contactButtonHref"),
          target: "_blank",
        }}
        buttonSecondary={{
          label: translate("dashboard.infoCards.contact.scheduleButton"),
          leftIcon: "CalendarTimeIcon",
          size: "32",
          href: translate("dashboard.infoCards.contact.scheduleButtonHref"),
          target: "_blank",
        }}
      />
      <CustomModal
        ref={doubtModal}
        ModalTitle={
          <div className="H4 cardsBroker-modalTitle">
            <GlobalIcon iconName="HelpIcon" size="XS" color="currentColor" />
            {doubt ? doubt.label : ""}
          </div>
        }
        ModalBody={
          <div className="cardsBroker-doubtModalBody">
            <p
              className="BodyM"
              dangerouslySetInnerHTML={{ __html: doubt ? doubt.body : "" }}
            />
            <ButtonDS
              label={translate("dashboard.infoCards.modal.moreInfo")}
              ghost
              className="cardsBroker-doubtModalCTA"
              href={doubt ? doubt.href : ""}
              target="_blank"
            />
          </div>
        }
      />
      <CustomModal
        closeModalCallback={() => setLinkCopied(false)}
        ref={shareModal}
        ModalTitle={
          <div className="H4 cardsBroker-modalTitle">
            <GlobalIcon iconName="ShareIcon" size="XS" color="currentColor" />
            {translate("dashboard.infoCards.shareModal.title")}
          </div>
        }
        ModalBody={
          <div className="cardsBroker-shareModalBody">
            <p
              className="BodyM"
              dangerouslySetInnerHTML={{
                __html: translate("dashboard.infoCards.shareModal.bodyMedia"),
              }}
            />
            <div className="cardsBroker-shareModalMedias">
              <IconButton
                size="50"
                icon="WhatsappIcon"
                buttonType="secondary"
                aria-label="share on whatsapp"
                href={`whatsapp://send?text=${encodeURIComponent(
                  "Calcula tu precio estimado con Life5 en:"
                )}%20${encodeURIComponent(broker ? broker.landing : "")}`}
                target="_blank"
              />
              <IconButton
                size="50"
                icon="LinkedinIcon"
                buttonType="secondary"
                aria-label="share on linkedin"
                href={`http://www.linkedin.com/shareArticle?mini=true&url=${encodeURIComponent(
                  broker ? broker.landing : ""
                )}`}
                target="_blank"
              />
              <IconButton
                size="50"
                icon="FacebookIcon"
                buttonType="secondary"
                aria-label="share on facebook"
                href={`https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
                  broker ? broker.landing : ""
                )}`}
                target="_blank"
              />
            </div>
            <p
              dangerouslySetInnerHTML={{
                __html: translate("dashboard.infoCards.shareModal.bodyUrl"),
              }}
            />
            <div className="cardsBroker-copyClipboardWrapper">
              <div className="cardsBroker-clipboardInput">
                <GlobalIcon iconName="LinkIcon" size="XS" color="#424242" />
                <input
                  onClick={(e: any) => {
                    e.target.select();
                  }}
                  value={broker ? broker.landing : ""}
                  readOnly
                />
              </div>
              <ButtonDS
                label={
                  linkCopied
                    ? translate("dashboard.infoCards.shareModal.copied")
                    : translate("dashboard.infoCards.shareModal.copy")
                }
                onClick={() => {
                  navigator.clipboard.writeText(broker ? broker.landing : "");
                  if (!linkCopied) setLinkCopied(true);
                }}
                size="40"
              />
            </div>
          </div>
        }
      />
    </div>
  );
};

export default CardsBroker;
