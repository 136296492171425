import PropTypes from "prop-types";
import React from "react";
import { Badge } from "./Badge";

interface Props {
    title: string;
    link1: string;
    status: "selected" | "default";
    className: any;
    badgeAtomAvatarIcon: JSX.Element;
    onClick: () => void;
}

export const UniqueStatus = ({
    title = "Title",
    link1 = "Link",
    status,
    className,
    badgeAtomAvatarIcon = <></>,
    onClick
}: Props): JSX.Element => {
    return (
        <div
            className={`min-w-[107px] w-fit flex flex-1 flex-col items-center gap-[16px] pt-0 px-0 relative h-full ${status === "selected" ? "rounded-[8px]" : ""
                } ${status === "selected" ? "bg-[var(--light-primary-color,#ECF0FF)]" : ""} ${className}`}
        >
                <div
                    className={`text-dark-gray-100 text-center relative font-bold BodyS`}
                >
                    {title}
                </div>
            


            <Badge
                atomAvatarIcon={badgeAtomAvatarIcon}
                atomAvatarTypeIconSizeClassName={status === "selected" ? "!bg-white" : "!bg-[var(--light-primary-color,#ECF0FF)]"}
            />

            
                <p
                    className={`pointer BodyS font-bold w-fit underline text-center whitespace-nowrap relative ${status === "selected" ? "text-dark-gray-60" : "text-dark-gray-100"
                        }`}
                    onClick={() => onClick()}
                >
                    {link1}
                </p>
          
        </div>
    );
};

UniqueStatus.propTypes = {
    title: PropTypes.string,
    link: PropTypes.bool,
    link1: PropTypes.string,
    status: PropTypes.oneOf(["selected", "default"]),
    href: PropTypes.string,
};
