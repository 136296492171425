import { getValidateEmail, typeInsurance } from "../../../utils/staticData";

import { errorMessage } from "../../../utils/error";
import InfoMessage from "../../Rebranding/atoms/InfoMessage/InfoMessage.component";
import AlertIcon from "../../Rebranding/atoms/Icons/AlertIcon.component";
import "./formCalcPrice.styles.scss";
import numberFormat from "../../../utils/numberFormat";
import {
  ButtonDS,
  GlobalIcon,
  InputDS,
  SelectDS,
  Toggle,
  Typography,
} from "get-life-storybook-ts";

export default function FormCalcPrice(props) {
  const {
    buttonCalculate,
    capitalLimits,
    death,
    disability,
    error,
    isActiveRelatedEmail,
    isGetlifeBroker,
    handleSubmitOrganic,
    setCapital,
    setInsuranceType,
    setIsActiveRelatedEmail,
    setValue,
    showAlerts,
    translate,
    values,
    showResults,
    setError,
    validateBirthdate,
  } = props;

  const textCapital = `${numberFormat(
    capitalLimits.minCapital,
    "capital"
  )} - ${numberFormat(capitalLimits.maxCapital, "capital")}`;

  const isDisability = disability !== undefined && !disability;

  let form = values;
  if (isDisability) {
    form = {
      ...form,
      insuranceType: { value: "basic", label: translate("info.death") },
    };
  }

  const clearInputError = (field) => {
    if (error) {
      const updatedError = [...error];
      const errorItem = updatedError.find((e) => e.field === field);

      if (errorItem) {
        errorItem.message = "";
        errorItem.field = "";
        setError(updatedError);
      }
    }
  };

  return (
    <>
      <div className="newDashboard-tariffifierGrid">
        <div className="newDashboard-tariffifierGridColumn">
          <InputDS
            label={translate("calculator.label.birthday")}
            type="date"
            onChange={(e) => setValue("date", e.target.value)}
            onBlur={() => {
              clearInputError("date");
              validateBirthdate();
            }}
            value={form["date"]}
            error={errorMessage(error, "date")}
            helper={translate("dashboard.calculator.maxAge")}
            icon="CalendarEventLineIcon"
          />
          <InputDS
            name="referenceName"
            value={form["referenceName"]}
            label={translate("calculator.label.referenceName")}
            icon="UserSearchIcon"
            placeholder={translate("calculator.placeholder.referenceName")}
            onChange={(e) => setValue("referenceName", e.target.value)}
            onBlur={() => clearInputError("referenceName")}
            error={errorMessage(error, "referenceName")}
          />
          <InputDS
            value={form["phone"]}
            name="phone"
            label={translate("calculator.label.phone")}
            icon="DeviceMobile"
            placeholder={translate("calculator.placeholder.phone")}
            onChange={(e) => setValue("phone", e.target.value)}
            onBlur={() => clearInputError("phone")}
            error={errorMessage(error, "phone")}
          />
          <InputDS
            value={getValidateEmail(form["email"])}
            name="email"
            label={translate("calculator.label.email")}
            icon="MailIcon"
            placeholder={translate("calculator.placeholder.email")}
            onChange={(e) => setValue("email", e.target.value)}
            onBlur={() => clearInputError("email")}
            error={errorMessage(error, "email")}
          />
          {isGetlifeBroker && !isActiveRelatedEmail && (
            <ButtonDS
              ghost
              buttonType="tertiary"
              label={translate("dashboard.calculator.addRelatedClient")}
              onClick={() => setIsActiveRelatedEmail(true)}
              size="32"
              leftIcon="PlusIcon"
              className="newDashboard-addRelatedClient"
            />
          )}
          {isActiveRelatedEmail && (
            <InputDS
              value={getValidateEmail(form["relatedEmail"])}
              name="relatedEmail"
              label={translate("calculator.label.emailRelated")}
              icon="MailForwardIcon"
              placeholder={translate("calculator.placeholder.emailRelated")}
              onChange={(e) => setValue("relatedEmail", e.target.value)}
              onBlur={() => clearInputError("relatedEmail")}
              error={errorMessage(error, "relatedEmail")}
            />
          )}
        </div>
        <div className="newDashboard-tariffifierGridColumn">
          <InputDS
            type="number"
            label={translate("calculator.label.capital")}
            name="capital"
            value={form["capital"]}
            onChange={(e) => {
              const value = !e.target.value
                ? capitalLimits.minCapital
                : e.target.value;
              setCapital(value, setValue);
            }}
            onBlur={() => {
              let value = form["capital"];
              if (value > capitalLimits.maxCapital) {
                value = capitalLimits.maxCapital;
              }
              if (value < capitalLimits.minCapital) {
                value = capitalLimits.minCapital;
              }
              setCapital(value, setValue);
            }}
            error={death && errorMessage(error, "capital")}
            disabled={!form["capital"]}
            icon="CurrencyEuroIcon"
            onKeyDown={(e) => {
              if (e.key === "," || e.key === ".") e.preventDefault();
            }}
            helper={
              capitalLimits.minCapital && capitalLimits.maxCapital
                ? `${translate(
                    "edit.projects.minimum"
                  )} ${new Intl.NumberFormat("de-DE", {
                    style: "currency",
                    currency: "EUR",
                    maximumFractionDigits: 0,
                  }).format(capitalLimits.minCapital)}, ${translate(
                    "edit.projects.maximum"
                  )} ${new Intl.NumberFormat("de-DE", {
                    style: "currency",
                    currency: "EUR",
                    maximumFractionDigits: 0,
                  }).format(capitalLimits.maxCapital)}`
                : null
            }
            step={5000}
            min={capitalLimits.minCapital}
            max={capitalLimits.maxCapital}
          />
          <div>
            <p className="BodyL newDashboard-fieldLabel">
              {translate("calculator.label.insuranceType")}
            </p>
            <div className="flex flex-row justify-between items-center">
              <span className="BodyM font-medium text-[#424242] flex flex-row items-center gap-[8px]">
                <GlobalIcon iconName="DisabledIcon" size="XS" />
                {translate("dashboard.calculator.ipa")}
              </span>
              <div className="newDashboard-coverageToggle">
                <span
                  className="BodyM font-bold"
                  style={{
                    color:
                      form.insuranceType.value === "basic"
                        ? "#A0A0A0"
                        : "var(--primary-color)",
                    transition: "0.4s",
                  }}
                >
                  {translate("dashboard.calcualtor.included")}
                </span>
                <Toggle
                  labels={[]}
                  onValueChange={(value) => {
                    const types = typeInsurance(translate);
                    let newValue = {};
                    if (value) {
                      newValue = types.find((t) => t.value === "premium");
                    } else {
                      newValue = types.find((t) => t.value === "basic");
                    }
                    setValue("insuranceType", newValue);
                    setInsuranceType(newValue);
                  }}
                  disabled={disability === false}
                />
              </div>
            </div>
          </div>
          {isGetlifeBroker ? (
            <SelectDS
              options={[
                {
                  label: translate("dashboard.calculator.market.wlEs"),
                  value: "axa-wl",
                },
                {
                  label: translate("dashboard.calculator.market.es"),
                  value: "life5",
                },
              ]}
              label={translate("dashboard.calculator.market.label")}
              placeholder={translate("dashboard.calculator.market.placeholder")}
              value={form["market"]}
              onChange={(e) => setValue("market", e.target.value)}
            />
          ) : null}
        </div>
      </div>
      <div className="mt-[24px] flex">
        <ButtonDS
          label={translate(`dashboard.buttons.${buttonCalculate}`)}
          rightIcon={showResults ? undefined : "ArrowRightIcon"}
          className="newDashboard-tariffifierCTA"
          onClick={() => handleSubmitOrganic(form, setValue)}
          buttonType={showResults ? "tertiary" : "primary"}
        />
      </div>
      {showAlerts.recalcDisability && (
        <InfoMessage
          className="infoMessage"
          classNameText="infoMessage-text"
          icon={
            <AlertIcon
              style={{ minWidth: "25px" }}
              color="#423EF4"
              width={30}
              height={30}
            />
          }
          message={
            <>
              {translate("info.dashboard.recalculateGuarantee")}{" "}
              <Typography
                variant="BodyMedium"
                weight="700"
                className="infoMessage-emphasis"
                text={translate("info.death")}
              />
            </>
          }
          type="info"
        />
      )}
      {showAlerts.recalcMaxCapital && (
        <InfoMessage
          className="infoMessage"
          classNameText="infoMessage-text"
          icon={
            <AlertIcon
              style={{ minWidth: "25px" }}
              color="#423EF4"
              width={30}
              height={30}
            />
          }
          message={
            <>
              {translate("info.dashboard.recalculate")}{" "}
              <Typography
                variant="BodyMedium"
                weight="700"
                className="infoMessage-emphasis"
                text={textCapital}
              />
            </>
          }
          type="info"
        />
      )}
    </>
  );
}
