import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { ContextGetlife } from "../../../contexts/ContextGetlife";
import Add from "./../../../assets/img/add.svg";
import HeadPhones from "./../../../assets/img/headPhones.svg";
import "./css/whiteButton.scss";
import { I18nContext } from "../../../contexts/i18n.context";

export default function WhiteButton(props) {
  const { content, onClick } = props;
  const { setEmail } = useContext(ContextGetlife);
  const {
    state: { translate },
  } = useContext(I18nContext);

  const navigate = useNavigate();
  return (
    <>
      {props.content === translate("tables.incidents.buttonNew") && (
        <button
          className="addButton-main"
          onClick={() => {
            setEmail("");
            navigate("/incidents/newticket");
          }}
        >
          <div className="addButton-imgContainer">
            <p>{content}</p>

            <img src={Add} alt="Notificar incidencia" />
          </div>
        </button>
      )}

      {content === translate("incidents.button.talk") && (
        <button className="addButton-mainHeadPhone">
          <div
            className="addButton-imgContainerHeadPhone"
            onClick={() => props.handleOpenTalk()}
          >
            <img src={HeadPhones} alt="Hablar con mi gestor" />
            <p>{content}</p>
          </div>
        </button>
      )}

      {(content === translate("dashboard.button.contract") || content === translate("dashboard.button.contractSelectra")) && (
        <button
          className={"addButton-mainHeadPhone"}
          onClick={() => (props.onClick ? onClick() : "")}
        >
          <div className="addButton-imgContainerHeadPhone">
            <p>{content}</p>
          </div>
        </button>
      )}
    </>
  );
}
