import AlertTriangleIcon from "get-life-storybook-ts/lib/components/Icons/AlertTriangleIcon";
import ZoomQuestionIcon from "get-life-storybook-ts/lib/components/Icons/ZoomQuestionIcon";
import CreditCardOff from "get-life-storybook-ts/lib/components/Icons/CreditCardOff";
import XBrokerIcon from "get-life-storybook-ts/lib/components/Icons/XBrokerIcon";
import ShieldCheckIcon from "get-life-storybook-ts/lib/components/Icons/ShieldCheckIcon";
import FileDislikeIcon from "get-life-storybook-ts/lib/components/Icons/FileDislikeIcon";
import ArrowRightLinesIcon from "get-life-storybook-ts/lib/components/Icons/ArrowRightLinesIcon";
import PendingSignIcon from "get-life-storybook-ts/lib/components/Icons/PendingSignIcon";
import PendingNoteIcon from "get-life-storybook-ts/lib/components/Icons/PendingNoteIcon";
import FileEuroIcon from "get-life-storybook-ts/lib/components/Icons/FileEuroIcon";
import PersonalDataIcon from "get-life-storybook-ts/lib/components/Icons/PersonalDataIcon";
import FileXIcon from "get-life-storybook-ts/lib/components/Icons/FileXIcon";
import { ChipTypes } from "get-life-storybook-ts/lib/atoms/Chip/Chip";
import { IconNameT } from "get-life-storybook-ts";
import { ReactNode } from "react";

interface stageI {
    title: string;
    value: string;
    icon: JSX.Element;
    chipType: ChipTypes;
    chipIcon: IconNameT;
}

export const Stages = (translate: (key: string) => string) : stageI[] =>[
    {
        title: translate("projects.stage.questionarie"),
        value: "product_questions_pending,questions_pending,prestack_pending,personaldata_pending,telesubscription",
        icon: <ZoomQuestionIcon width={34} height={34} color="var(--primary-color,#423EF4)"/>,
        chipType: "warning",
        chipIcon: "ZoomQuestionIcon"
    },
    {
        title: translate("projects.stage.prices"),
        value: "upsell_pending",
        icon: <FileEuroIcon width={34} height={34} color="var(--primary-color,#423EF4)"/>,
        chipType: "primary",
        chipIcon: "FileEuroIcon"
    },{
        title: translate("projects.stage.personalData"),
        value: "nationality_pending,address_pending,beneficiaries_pending,beneficiariestypes_pending,physicalperson_pending,bankingentity_pending,legalperson_pending",
        icon: <PersonalDataIcon width={34} height={34} color="var(--primary-color,#423EF4)" />,
        chipType: "default",
        chipIcon: "PersonalDataIcon"
    }, {
        title: translate("projects.stage.payment"),
        value: "paymentmethodpending_pending,payment_pending,payment_validation_pending",
        icon: <CreditCardOff />,
        chipType: "neutral",
        chipIcon: "CreditCardOff"
    }, {
        title: translate("projects.stage.documentation"),
        value: "idcard_pending,docs_pending",
        icon: <FileXIcon width={34} height={34} color="var(--primary-color,#423EF4)" />,
        chipType: "neutral",
        chipIcon: "FileXIcon"
    }, {
        title: translate("projects.stage.legalNote"),
        value: "legalnote_pending",
        icon: <PendingNoteIcon width={34} height={34} color="var(--primary-color,#423EF4)"/>,
        chipType: "secondary",
        chipIcon: "PendingNoteIcon"
    }, {
        title: translate("projects.stage.signin"),
        value: "signature_pending",
        icon: <PendingSignIcon width={34} height={34} color="var(--primary-color,#423EF4)"/>,
        chipType: "successDark",
        chipIcon: "PendingSignIcon"
    }, {
        title: translate("projects.stage.process"),
        value: "ops_review,it_review,insurer_review,csv_pending",
        icon: <ArrowRightLinesIcon width={34} height={34} color="var(--primary-color,#423EF4)"/>,
        chipType: "success",
        chipIcon: "ArrowRightLinesIcon"
    }, {
        title: translate("projects.stage.policy"),
        value: "complete",
        icon: <ShieldCheckIcon width={34} height={34} color="var(--primary-color,#423EF4)"/>,
        chipType: "success",
        chipIcon: "ShieldCheckIcon"
    }, {
        title: translate("projects.stage.canceled"),
        value: "canceled",
        icon: <FileDislikeIcon width={34} height={34} color="var(--primary-color,#423EF4)"/>,
        chipType: "destructive",
        chipIcon: "FileDislikeIcon"
    }, {
        title: translate("projects.stage.rejected"),
        value: "rejected",
        icon: <XBrokerIcon width={34} height={34} color="var(--primary-color,#423EF4)"/>,
        chipType: "destructive",
        chipIcon: "XBrokerIcon"
    }, {
        title: translate("projects.stage.claim"),
        value: "claim",
        icon: <AlertTriangleIcon width={34} height={34} color="var(--primary-color,#423EF4)" />,
        chipType: "destructive",
        chipIcon: "AlertTriangleIcon"
    }
];
  