import ErrorResponse from "./ErrorResponse";
import HttpException from "./HttpException";
import HttpMethod from "./HttpMethod";
import { HTTP_SUCCESS_STATUS } from "./ResponseStatus";

interface Fetch {
  path: string | RequestInfo;
  body?: any;
  method?: HttpMethod;
  extraHeaders?: any;
  signal?: AbortSignal
  throwError?: boolean;
}

const parseError = async (response: Response): Promise<ErrorResponse> => {
  let error: ErrorResponse;
  try {
    const errorJson = await response.json();
    error = {
      status: response.status,
      error: errorJson.errorCode,
      message:
        errorJson.errorMessage || errorJson.message || errorJson.brokerLanding,
    };
  } catch (e) {
    error = {
      status: response.status,
      error: response.statusText,
      message: "",
    };
  }

  return error;
};

const createResponse = async (response: Response, throwError: boolean): Promise<any> => {
  const responseStatus = response.status;
  let result: any;
  if (!HTTP_SUCCESS_STATUS.includes(responseStatus) && throwError) {
    const responseData = await parseError(response);
    throw new HttpException(responseData);
  }
  try {
    result = await response.json();
  } catch (e) {
    result = response;
  }
  return result;
};

const fetchRequest = ({
  path,
  body,
  method,
  extraHeaders,
  signal,
  throwError = true
}: Fetch): Promise<any> =>
  new Promise((resolve, reject) => {
    const sendInformation = body
      ? {
          method: method,
          body: JSON.stringify(body),
        }
      : {};
    fetch(path, {
      headers: {
        ...extraHeaders,
      },
      ...sendInformation,
      signal
    })
      .then((response) => {
        return createResponse(response, throwError);
      })
      .then(resolve)
      .catch(reject);
  });

export const get = (path: string | RequestInfo, headers?: any, signal?: AbortSignal, throwError: boolean = true) =>
  fetchRequest({ path, extraHeaders: headers, signal, throwError });

export const post = (path: string, body: any, headers?: any, throwError: boolean = true) =>
  fetchRequest({ path, body, method: HttpMethod.POST, extraHeaders: headers, throwError });

export const put = (path: string, body: any, headers?: any, throwError: boolean = true) =>
  fetchRequest({ path, body, method: HttpMethod.PUT, extraHeaders: headers, throwError });

export const patch = (path: string, body: any, headers?: any, throwError: boolean = true) =>
  fetchRequest({
    path,
    body,
    method: HttpMethod.PATCH,
    extraHeaders: headers,
    throwError
  });

export const delet = (path: string, body?: any, headers?: any, throwError: boolean = true) =>
  fetchRequest({
    path,
    body,
    method: HttpMethod.DELETE,
    extraHeaders: headers,
    throwError
  });
