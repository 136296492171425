import { LanguageT } from "../../../../contexts/ContextGetlife";
import { PhonePrefix } from "../../../../utils/InternationlChanges";

const DOMAIN = process.env.REACT_APP_DOMAIN_LOCALE!;

export const formatPhone = (phone?: string) => {
  const phoneWithoutPrefiex = phone
    ? phone.replace(
        PhonePrefix[DOMAIN as LanguageT].prefix,
        PhonePrefix[DOMAIN as LanguageT].replace
      )
    : "";

  let formatedPhone: string = phoneWithoutPrefiex;

  if (formatedPhone === "wrong-number") {
    return (formatedPhone = "");
  } else if (formatedPhone !== "") {
    if (DOMAIN === "es") {
      formatedPhone = formatedPhone.match(/.{3}/g)?.join(" ")!;
    }
    if (DOMAIN === "it") {
      formatedPhone = formatedPhone.slice(0, 3) + " " + formatedPhone.slice(3);
    }
    if (DOMAIN === "fr") {
        formatedPhone = formatedPhone.match(/.{2}/g)?.join(" ")!;
    }
  }

  return (
    <p>
      {formatedPhone?.length !== undefined && formatedPhone?.length > 1
        ? formatedPhone
        : ""}
    </p>
  );
};
