// Components
import SelectGetlife from "../select/SelectGetlife";
// Controller
import DateRangeController from "./controllers/date-range.controller";
// Styles
import "./date-range.component.scss";

export const DateRange = ({
  options,
  placeholderDateRange,
  setEndDate,
  setIndexPage,
  setStartDate,
  translate
}) => {
  const { handleSelect } = DateRangeController({
    setEndDate,
    setIndexPage,
    setStartDate,
  });
  return (
    <>
      <div className="date-range__content">
        <label htmlFor="">{translate("dateRange.label.filter")}</label>
        <div className="date-range__select">
          <SelectGetlife
            type="range"
            toMap={options}
            handleValues={handleSelect}
            placeholder={placeholderDateRange}
          />
        </div>

      </div>
    </>
  );
};
