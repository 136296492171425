import { ButtonDS, Chip, ModalDS } from "get-life-storybook-ts";
import { ViewProjectData } from "../../api/request/Lead/Model/EditProjectReponse.model";
import { useContext, useEffect, useState } from "react";
import { I18nContext } from "../../contexts/i18n.context";
import apiGetDownloadableFiles from "../../api/request/apiGetDownloadableFiles";
import apiDownloadPdfTemplates from "../../api/request/apiDownloadPdfTemplates";
import DownloadableFilesModalDS from "../components/DownloadableFiles/DownloadableFilesModalDS";

interface ViewProjectI {
  viewData: ViewProjectData;
  viewModal: boolean;
  setViewModal: (value: boolean) => void;
}

const ViewProject = ({ viewData, viewModal, setViewModal }: ViewProjectI) => {
  const {
    state: { translate },
  } = useContext(I18nContext);
  const isRejected = viewData.status === "rejected";
  const [downloadableModal, setDownloadableModal] = useState(false);

  const [downloadablePdfs, setDownloadablePdfs] = useState<
    [{ label: string; href: string }] | []
  >([]);

  const fetchDownloadables = async (
    leadId: string,
    coverage: string,
    status: string
  ) => {
    const downloadablePdfs = await apiGetDownloadableFiles(leadId, coverage);
    const contract: any[] = [];
    if (
      status === "ops_review" ||
      status === "insurer_review" ||
      status === "it_review" ||
      status === "complete"
    ) {
      await apiDownloadPdfTemplates(leadId, "contract")
        .then((response) => {
          if (response.pdfUrl)
            contract.push({
              label: translate("view.project.downloadContract"),
              href: response.pdfUrl,
            });
        })
        .catch((e) => console.log("contract error", e));
    }
    setDownloadablePdfs([...downloadablePdfs, ...contract] as [
      { label: string; href: string }
    ]);
  };

  useEffect(() => {
    (async () => {
      if (Object.keys(viewData).length > 0) {
        await fetchDownloadables(
          viewData.leadId,
          viewData.coverage,
          viewData.status
        );
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [viewData]);

  const onClose = () => {
    setDownloadablePdfs([]);
    setViewModal(false);
  };

  const coverageLabel =
    {
      basic: "view.project.basic",
      premium: "view.project.premium",
      eg: "view.project.eg",
    }[viewData.coverage] || "";

  return (
    <>
      <ModalDS
        open={viewModal}
        onClose={onClose}
        icon="EyeIcon"
        title={translate("view.project.title")}
        content={
          <div>
            <div className="py-[16px] flex flex-col border-b border-[#E2E2E2]">
              <span className="BodyM text-[#7B7B7B] font-medium">
                {translate("view.project.insuredDate")}
              </span>
              <span className="pb-[16px] BodyL text-[#424242] font-medium">
                {viewData.fullName ?? translate("view.project.notAvailable")}
              </span>
              <div className="pl-[24px] flex flex-col">
                <span className="BodyS text-[#7B7B7B] font-medium">
                  {translate("view.project.email")}
                </span>
                <span className="pb-[16px] BodyM text-[#424242] font-medium">
                  {viewData.email ?? translate("view.project.notAvailable")}
                </span>

                <span className="BodyS text-[#7B7B7B] font-medium">
                  {translate("view.project.phone")}
                </span>
                <span className="pb-[16px] BodyM text-[#424242] font-medium">
                  {viewData.phone ?? translate("view.project.notAvailable")}
                </span>
                {isRejected ? null : (
                  <>
                    <span className="BodyS text-[#7B7B7B] font-medium">
                      {translate("view.project.adress")}
                    </span>
                    <span className="BodyM text-[#424242] font-medium">
                      {viewData.fullAddress ??
                        translate("view.project.notAvailable")}
                    </span>
                  </>
                )}
              </div>
            </div>
            <div className="py-[16px] flex flex-col border-b border-[#E2E2E2]">
              {isRejected ? (
                <>
                  <span className="BodyM text-[#7B7B7B] font-medium">
                    {translate("view.project.rejectedData")}
                  </span>
                  <div className="grid grid-cols-2 gap-x-[32px] gap-y-[16px] mt-[16px]">
                    <p className="BodyS text-[#7B7B7B] font-medium pl-[24px]">
                      {translate("view.project.creationDate")}
                      <span className="block text-[#424242] BodyM">
                        {viewData.createdAt ??
                          translate("view.project.notAvailable")}
                      </span>
                    </p>
                    <p className="BodyS text-[#7B7B7B] font-medium pl-[24px]">
                      {translate("view.project.rejectedDate")}
                      <span className="block text-[#424242] BodyM">
                        {viewData.rejectedDate ??
                          translate("view.project.notAvailable")}
                      </span>
                    </p>
                    <p className="BodyS text-[#7B7B7B] font-medium pl-[24px] col-span-2">
                      {translate("view.project.rejectionReason")}
                      <span className="block text-[#424242] BodyM">
                        {viewData.rejectionCause ??
                          translate("view.project.notAvailable")}
                      </span>
                    </p>
                  </div>
                </>
              ) : (
                <>
                  <span className="BodyM text-[#7B7B7B] font-medium">
                    {translate("view.project.policyData")}
                  </span>
                  <span className="pb-[16px] BodyL text-[#424242] font-medium">
                    {viewData.policyNumber ??
                      translate("view.project.notAvailable")}
                  </span>
                  <div className="grid grid-cols-2 gap-x-[32px] gap-y-[16px]">
                    <p className="BodyS text-[#7B7B7B] font-medium pl-[24px]">
                      {translate("view.project.capital")}
                      {viewData.capital ? (
                        <Chip
                          text={new Intl.NumberFormat("de-DE", {
                            style: "currency",
                            currency: "EUR",
                            minimumFractionDigits: 0,
                            maximumFractionDigits: 0,
                          }).format(viewData.capital)}
                          type="success"
                          icon={null}
                        />
                      ) : (
                        translate("view.project.notAvailable")
                      )}
                    </p>
                    <p className="BodyS text-[#7B7B7B] font-medium pl-[24px]">
                      {translate("view.project.purchaseDate")}
                      <span className="block text-[#424242] BodyM">
                        {viewData.purchaseDate ??
                          translate("view.project.notAvailable")}
                      </span>
                    </p>
                    <p className="BodyS text-[#7B7B7B] font-medium pl-[24px]">
                      {translate("view.project.capital")}
                      {viewData.coverage ? (
                        <Chip
                          text={translate(coverageLabel)}
                          type="success"
                          icon={null}
                        />
                      ) : (
                        translate("view.project.notAvailable")
                      )}
                    </p>
                    <p className="BodyS text-[#7B7B7B] font-medium pl-[24px]">
                      {translate("view.project.effectDate")}
                      <span className="block text-[#424242] BodyM">
                        {viewData.effectDate ??
                          translate("view.project.notAvailable")}
                      </span>
                    </p>
                    <p className="BodyS text-[#7B7B7B] font-medium pl-[24px]">
                      {translate("view.project.pay")}
                      <span className="block text-[#424242] BodyM">
                        {viewData.premium
                          ? new Intl.NumberFormat("de-DE", {
                              style: "currency",
                              currency: "EUR",
                            }).format(viewData.premium) +
                            (viewData.frequency === "MONTHLY"
                              ? translate("view.project.monthly")
                              : translate("view.project.yearly"))
                          : translate("view.project.notAvailable")}
                      </span>
                    </p>
                    <p className="BodyS text-[#7B7B7B] font-medium pl-[24px]">
                      {translate("view.project.renewalDate")}
                      <span className="block text-[#424242] BodyM">
                        {viewData.renewalDate ??
                          translate("view.project.notAvailable")}
                      </span>
                    </p>
                    <p className="BodyS text-[#7B7B7B] font-medium pl-[24px]">
                      {translate("view.project.paymenthMethod")}
                      <span className="block text-[#424242] BodyM">
                        {viewData.paymentMethod && viewData.lastNumbers
                          ? `${translate(
                              `view.project.${viewData.paymentMethod}`
                            )} ${viewData.lastNumbers}`
                          : null}
                      </span>
                    </p>
                    <p className="BodyS text-[#7B7B7B] font-medium pl-[24px]">
                      {translate("view.project.renewal")}
                      <span className="block text-[#424242] BodyM">
                        {viewData.renewalPremium
                          ? new Intl.NumberFormat("de-DE", {
                              style: "currency",
                              currency: "EUR",
                            }).format(viewData.renewalPremium) +
                            (viewData.frequency === "MONTHLY"
                              ? translate("view.project.monthly")
                              : translate("view.project.yearly"))
                          : translate("view.project.notAvailable")}
                      </span>
                    </p>
                  </div>
                </>
              )}
            </div>
          </div>
        }
        buttons={
          <>
            {isRejected ? null : (
              <ButtonDS
                label={translate("view.project.downloadables")}
                buttonType="tertiary"
                leftIcon="FileDownload"
                className="w-full"
                onClick={() => setDownloadableModal(true)}
              />
            )}
          </>
        }
      />
      <DownloadableFilesModalDS
        open={downloadableModal}
        setOpen={setDownloadableModal}
        downloadablePdfs={downloadablePdfs}
      />
    </>
  );
};

export default ViewProject;
