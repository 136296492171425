import ButtonPrimary from "../../../components/buttons/ButtonPrimary";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import { intentionCheckOut } from "../../../../utils/staticData";
import "./cards.scss";
import CrossedOut from "../../../Rebranding/atoms/CrossedOut/CrossedOut.component";
import TicketIcon from "../../../Rebranding/atoms/Icons/TicketIcon";
import InfoBox from "../../../Rebranding/molecules/InfoBox/InfoBox.component";
import numberFormat from "../../../../utils/numberFormat";
import IconButton from "../../../Rebranding/atoms/Buttons/IconButton/IconButton.component";
import Button from "../../../Rebranding/atoms/Buttons/ButtonBase/Button.component";
import DownloadableFilesModal from "../../../components/DownloadableFiles/DownloadableFilesModal";
import { useState } from "react";

export default function Cards(props) {
  const [downloadableOpen, setDownloadableOpen] = useState(false)
  const {
    card,
    complete,
    contract,
    handleMoreInfo,
    promotion,
    pdfElement,
    setShowModalInfo,
    translate,
    downloadableFiles,
    isWholeYearAppliedPromo,
    pricing
  } = props;

  const {
    annualPrice,
    carrierId,
    monthlyPrice,
    questionsPending,
  } = card;
  return (
    <>
      <div
        className={
          questionsPending
            ? "claseProductoCard incompleto"
            : "claseProductoCard"
        }
      >
        <div className="product-box">
          <h5>{translate("checkpoint.cardheader.title")}</h5>
          <p>Life5 {intentionCheckOut[props.intention]}</p>
        </div>
      </div>
      <div
        className={
          questionsPending
            ? "precioProductoCard incompleto"
            : "precioProductoCard"
        }
      >
        <div className="box-up">
          <div className="price-box">
            {promotion && isWholeYearAppliedPromo && (
              <CrossedOut
                className="precioMes"
                data={`${numberFormat(pricing.monthlyPrice, "price")} ${translate("card.period.monthly")}`}
              />
            )}
            <p className="precioMes">{`${numberFormat(
              (promotion && isWholeYearAppliedPromo) ? pricing.monthlyPromotedPrice : pricing.monthlyPrice,
              "price"
            )}${translate("card.period.monthly")}`}</p>
            {promotion && (
              <CrossedOut
                className="precioAnual"
                data={`${numberFormat(pricing.annualPrice, "price")} €${translate("card.period.yearly")}`}
              />
            )}
            <p className="precioAnual">{`${numberFormat(
              pricing.annualPromotedPrice,
              "price"
            )}${translate("card.period.yearly")}`}</p>
            {questionsPending ? (
              <p className="questionPendings">
                {translate("checkpoint.card.questionsPending")}
              </p>
            ) : (
              <p className="questionPendings"></p>
            )}
          </div>
          <div className="more-info-box">
            <Button buttonType="text" onClick={() => handleMoreInfo()}>
            {translate("checkpoint.card.moreInfo")}
            </Button>
          </div>
        </div>
        {promotion && (
          <InfoBox
            icon={<TicketIcon />}
            infoIcon={!!promotion.promotionalCode}
            onClick={() => setShowModalInfo(true)}
            text={promotion.description}
          />
        )}
        <div className="buttons-container">
          <div className="first-button">
            {questionsPending ? (
              <ButtonPrimary
                content={translate("checkpoint.button.continue")}
                className="upsell-personalization-add-button"
                onClick={() => complete(carrierId)}
              />
            ) : (
              <ButtonPrimary
                content={translate("checkpoint.button.contract")}
                className="upsell-personalization-add-button"
                onClick={() => contract()}
              />
            )}
          </div>
          <div className="second-button">
            <IconButton
              secondary
              className="second-button__icon"
              icon={<FileDownloadIcon />}
              reverse="reverse"
              label={translate("checkpoint.button.downloadables")}
              disabled={!downloadableFiles}
              onClick={() => setDownloadableOpen(true)}
            />
          </div>
        </div>
        <a
          ref={pdfElement}
          href
          target="_blank"
          id="downloadPDF"
          style={{ display: "none" }}
          rel="noreferrer"
        >
          ''
        </a>
      </div>
      <DownloadableFilesModal
        open={downloadableOpen}
        setOpen={setDownloadableOpen}
        downloadablePdfs={downloadableFiles}
      />
    </>
  );
}
