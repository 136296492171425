import { useState, useEffect, useContext } from "react";
import PaymentMain from "./PaymentMain";

import apiLeadPrice from "../../api/request/apiLeadPrice";
import { ContextGetlife } from "../../contexts/ContextGetlife";
import CouponConditions from "../Rebranding/organisms/Modal/CouponConditions/CouponConditions.component";
import { I18nContext } from "../../contexts/i18n.context";
import HttpCouponsRepository from "../../api/request/Coupons/Coupons.service";

const Payment = () => {
  const { leadId, token, setLeadId, brokerId, broker, utm } = useContext(ContextGetlife);
  const {
    state: { translate },
  } = useContext(I18nContext);

  const [election, setElection] = useState(true);
  const [paymentCard, setPaymentCard] = useState(false);
  const [paymentSepa, setPaymentSepa] = useState(false);
  const [productCard, setProductCard] = useState(false);
  const [couponInfo, setCouponInfo] = useState(false);
  const [showModalCoupon, setShowModalCoupon] = useState(false);

  const couponsRepository = new HttpCouponsRepository(leadId, token);

  const handlePaymentCard = () => {
    setPaymentCard(!paymentCard);
    setElection(false);
  };

  const handlePaymentSepa = () => {
    setPaymentSepa(!paymentSepa);
    setElection(false);
  };

  const undoSelectedPaymentMethod = () => {
    setElection(true);
    setPaymentSepa(false);
    setPaymentCard(false);
  }

  const fetchPromo = async ({ code, yearlyPrice }) => {
    try {
      const response = await couponsRepository.checkPromotionalCode({
        promotionalCode: code,
        yearlyPrice: yearlyPrice,
      });
      if (response.is_applicable_promo) {
        setCouponInfo({
          conditions: response.promo_conditions,
          exceptions: response.promo_exceptions,
          code: response.promo,
        });
      }
    } catch (error) { }
  };
  useEffect(() => {
    apiLeadPrice(leadId, token).then((res) => {
      const data = res;

      setProductCard(data);
      if (data.promotion) {
        fetchPromo({
          code: data.promotion.promotionalCode,
          yearlyPrice: data.annualPrice,
        });
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [leadId, token]);

  return (
    <>
      <CouponConditions
        translate={translate}
        showModal={showModalCoupon}
        setShowModal={setShowModalCoupon}
        couponInfo={couponInfo}
      />
      <PaymentMain
        brokerId={brokerId}
        election={election}
        handlePaymentCard={handlePaymentCard}
        handlePaymentSepa={handlePaymentSepa}
        undoSelectedPaymentMethod={undoSelectedPaymentMethod}
        leadId={leadId}
        paymentCard={paymentCard}
        paymentSepa={paymentSepa}
        productCard={productCard}
        setLeadId={setLeadId}
        setShowModalCoupon={setShowModalCoupon}
        token={token}
        translate={translate}
        broker={broker}
        utm={utm}
      />
    </>
  );
};

export default Payment;
