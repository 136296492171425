import * as React from "react";
import { useNavigate } from "react-router";
import HttpCouponsRepository from "../../../../api/request/Coupons/Coupons.service";
import HttpLeadRepository from "../../../../api/request/Lead/lead.service";
import { InsuranceType } from "../../../../api/request/Lead/Model/Request/CarrierPricesRequest.model";
import CarrierPricesResponse, {
  CarrierPrices,
  IntentType,
  PDF,
  Boundaries,
  Upgraded,
  Promotion,
  PricingI,
} from "../../../../api/request/Lead/Model/Response/CarrierPricesResponse.model";
import PostStackResponse from "../../../../api/request/Lead/Model/Response/PostStackResponse.model";
import { ContextGetlife } from "../../../../contexts/ContextGetlife";
import { I18nContext } from "../../../../contexts/i18n.context";
import {
  CoverageType,
  GenderTag,
  GoogleContext,
} from "../../../../contexts/GoogleTagManager.context";
import { GUARANTEE } from "../../../../utils/staticData";
import apiGetDownloadableFiles, {
  getTemplates,
} from "../../../../api/request/apiGetDownloadableFiles";
import { getTrackingUtms } from "../../../../api/request/apiLeadTrackingGet";

export type ValidateCouponT = "validate" | "invalidate" | "error";

export interface CouponInfoI {
  conditions?: string;
  exceptions?: string;
  error?: string;
  code?: string;
}

export interface CouponFetchI {
  code: string;
  yearlyPrice: number;
  func?: any;
  firstTime?: boolean;
}

type DownloadableFiles =
  | {
      label: string;
      href: string;
    }[]
  | undefined;

const CheckPointController = () => {
  const {
    leadId,
    setCarrierId,
    setLoading,
    token,
    broker,
    setShowNotification,
    brokerId,
  } = React.useContext(ContextGetlife);

  const {
    state: { translate },
  } = React.useContext(I18nContext);

  const { handleTrackerQuestion } = React.useContext(GoogleContext);

  const navigate = useNavigate();

  const [pricing, setPricing] = React.useState<PricingI>();
  const [carrierPrices, setCarrierPrices] = React.useState<CarrierPrices[]>([]);
  const [capital, setCapital] = React.useState<number>(0);
  const [insuranceType, setInsuranceType] = React.useState<InsuranceType>(null);
  const [intention, setIntention] = React.useState<IntentType>();
  const [isWholeYearAppliedPromo, setIsWholeYearAppliedPromo] =
    React.useState<boolean>();
  const [showModal, setShowModal] = React.useState<boolean>(false);
  const [showModalCoupon, setShowModalCoupon] = React.useState<boolean>(false);
  const [showModalInfo, setShowModalInfo] = React.useState<boolean>(false);
  const [premiumAllowed, setPremiumAllowed] = React.useState<boolean>(true);
  const [promotion, setPromotion] = React.useState<Promotion>();
  const pdfElement = React.useRef<HTMLElement | undefined>();
  const [upGradedOpen, setUpGradedOpen] = React.useState<boolean>(false);
  const [validateCoupon, setValidateCoupon] = React.useState<
    ValidateCouponT | undefined
  >();
  const [couponInfo, setCouponInfo] = React.useState<CouponInfoI>();
  const [boundaries, setBoundaries] = React.useState<Boundaries>(
    {} as Boundaries
  );
  const [upgraded, setUpgraded] = React.useState<Upgraded>({} as Upgraded);
  const [downloadableFiles, setDownloadableFiles] =
    React.useState<DownloadableFiles>();
  const [sendEvent, setSendEvent] = React.useState<boolean>(false);

  const leadRepository = new HttpLeadRepository(token);
  const couponsRepository = new HttpCouponsRepository(leadId, token);

  const fetchCarrierPrices = async (
    capital?: number,
    insuranceType?: InsuranceType
  ) => {
    let params = undefined;
    if (!!capital && !!insuranceType) {
      params = { capital, insuranceType };
    }
    try {
      const response: CarrierPricesResponse =
        await leadRepository.getCarrierPrices({ leadId, params });

      response.carrierPrices.sort((a, b) => {
        return a.monthlyPrice - b.monthlyPrice;
      });
      setCarrierPrices(response.carrierPrices);
      setCapital(response.capital);
      setInsuranceType(response.insuranceType);
      setIntention(response.intention);
      setPremiumAllowed(response.premiumAllowed);
      setPromotion(response.promotion);
      setIsWholeYearAppliedPromo(response.isWholeYearAppliedPromo);
      setPricing(response.pricing);

      const leadResponse = await leadRepository.getPostStackData(leadId);
      const utms = await getTrackingUtms(leadId);
      if (process.env.REACT_APP_DOMAIN_LOCALE === "fr") {
        if (response.capital > response.boundaries.maxCapital) {
          await handleCapital(
            response.boundaries.maxCapital,
            response.insuranceType
          );
        }
      }

      const event = "finalQuoteGenerated";
      const eventData = {
        leadId: leadId,
        page: window.location.href,
        intent: response.intention,
        isWholeYearAppliedPromo: response.isWholeYearAppliedPromo,
        calculatedCapital: response.capital,
        calculatedPremium:
          response.carrierPrices.length > 0
            ? response.carrierPrices[0]?.monthlyPrice
            : "",
        coverage: GUARANTEE[response.insuranceType!] as CoverageType,
        email: leadResponse.lead.email,
        phone: leadResponse.lead.phone ?? "",
        postalCode: leadResponse.lead.zipcode,
        gender: leadResponse.lead.gender as GenderTag,
        platform: "Broker",
        brokerEmployeeId: brokerId,
        brokerId: broker !== undefined ? broker.brokerageId : "",
        utm_source: utms.utm_source ?? "brokerapp",
        utm_medium:
          utms.utm_medium ?? (broker !== undefined ? broker.brokerageId : null),
        utm_campaign: utms.utm_campaign ?? brokerId,
        utm_content: utms.utm_content,
        utm_term: utms.utm_term,
        utm_test: utms.utm_test,
        fbclid: utms.fbclid,
        gclid: utms.gclid,
        entryPage: utms.entryPage,
        referrerUrl: utms.referrerUrl,
        ABTestVersion: utms.ABTestVersion,
      };

      if (!sendEvent) {
        handleTrackerQuestion({
          type: "FS",
          event: event,
          data: {
            birthDate: new Date(leadResponse.lead.birthdate),
            ...eventData,
          },
        });
        handleTrackerQuestion({
          type: "GA",
          data: {
            event: event,
            birthDate: leadResponse.lead.birthdate,
            ...eventData,
          },
        });

        setSendEvent(true);
      }

      if (!capital) {
        setBoundaries(response.boundaries);
        setUpGradedOpen(response.upgraded.upgraded);
        setUpgraded(response.upgraded);
      }
      if (response.promotion) {
        fetchPromo({
          code: response.promotion.promotionalCode,
          yearlyPrice: response.carrierPrices[0].annualPrice,
          firstTime: true,
        });
      }
    } catch (error) {}
    setLoading(false);
  };

  React.useEffect(() => {
    (async () => {
      if (!carrierPrices.length) {
        setLoading(true);
        await fetchCarrierPrices();
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [leadId]);

  React.useEffect(() => {
    (async () => {
      if (validateCoupon) {
        setLoading(true);
        await fetchCarrierPrices();
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [validateCoupon]);

  React.useEffect(() => {
    const getDownloadableFiles = async () => {
      const _downloadableFiles: DownloadableFiles =
        await apiGetDownloadableFiles(leadId, insuranceType);
      setDownloadableFiles(_downloadableFiles);
    };
    getDownloadableFiles();
  }, [leadId, insuranceType, capital]);

  const complete = (carrierId: string) => {
    setCarrierId(carrierId);
    navigate(`/questions`);
  };

  const contract = async () => {
    const data = {
      capital: capital,
      insuranceType: insuranceType,
    };

    try {
      const response: PostStackResponse = await leadRepository.recoverProject({
        leadId,
        data,
      });

      const checkMin = await leadRepository.checkMinimumAnnualPremium({
        leadId,
      });

      if (checkMin.upgraded.upgraded) {
        setCarrierPrices(checkMin.carrierPrices);
        setCapital(checkMin.capital);
        setInsuranceType(checkMin.insuranceType);
        setIntention(checkMin.intention);
        setPremiumAllowed(checkMin.premiumAllowed);
        setBoundaries(checkMin.boundaries);
        setUpGradedOpen(checkMin.upgraded.upgraded);
      } else {
        navigate(`/poststack`);
      }
    } catch (error) {}
  };

  const fetchPromo = async ({
    code,
    yearlyPrice,
    func,
    firstTime,
  }: CouponFetchI) => {
    try {
      const response = await couponsRepository.checkPromotionalCode({
        promotionalCode: code,
        yearlyPrice: yearlyPrice,
      });
      if (response.is_applicable_promo) {
        func && (await func(code));
        !firstTime && setValidateCoupon("validate");
        setCouponInfo({
          conditions: response.promo_conditions,
          exceptions: response.promo_exceptions,
          code: response.promo,
        });
        setShowModalCoupon(false);
      } else {
        !firstTime && setValidateCoupon("invalidate");
      }
    } catch (error) {}
  };

  const fetchPDF = async (templates: any) => {
    try {
      await leadRepository.recoverProject({
        leadId,
        data: {
          capital: capital,
          insuranceType: insuranceType,
        },
      });
      const response: PDF = await leadRepository.downloadPDF(leadId, templates);
      if (response.pdfUrl) {
        pdfElement.current?.setAttribute("href", response.pdfUrl);
        pdfElement.current?.click();
      }
    } catch (error) {}
  };

  const downloadPDF = async () => {
    const templates = getTemplates(
      process.env.REACT_APP_DOMAIN_LOCALE === "fr" ? "DEVIS" : insuranceType
    );

    await fetchPDF(templates);
  };

  const downloadPDFCards = async () => {
    const templates = getTemplates(
      process.env.REACT_APP_DOMAIN_LOCALE === "fr" ? "DEVIS" : insuranceType
    );

    await fetchPDF(templates);
  };

  const downloadPDFGuareantee = async () => {
    const templates = getTemplates(
      process.env.REACT_APP_DOMAIN_LOCALE === "fr" ? "DEVIS" : insuranceType
    );

    await fetchPDF(templates);
  };

  const handleCapital = async (newCapital: number, newIpa: InsuranceType) => {
    const data = {
      capital: newCapital,
      insuranceType: newIpa,
    };
    try {
      await leadRepository.recoverProject({
        leadId,
        data,
      });
      await fetchCarrierPrices();
    } catch (error) {}
  };

  const handleMoreInfo = () => {
    setShowModal(!showModal);
  };

  const handleRemoveCoupon = async (code: string) => {
    try {
      await couponsRepository.removeCoupon({
        promotionalCode: code,
      });
      setValidateCoupon("error");
      setCouponInfo(undefined);
      setShowNotification({
        message: translate("checkpoint.coupon.remove.correct"),
        status: true,
        type: "success",
        autoHideDuration: 5000,
      });
    } catch (error) {}
  };

  const handleClickIcon = async () => {
    if (promotion) {
      await handleRemoveCoupon(promotion.promotionalCode);
    } else {
      setShowModalCoupon(true);
    }
  };

  return {
    boundaries,
    broker,
    capital,
    carrierPrices,
    complete,
    contract,
    couponInfo,
    downloadPDF,
    downloadPDFCards,
    downloadPDFGuareantee,
    fetchPromo,
    handleCapital,
    handleMoreInfo,
    handleClickIcon,
    insuranceType,
    intention,
    isWholeYearAppliedPromo,
    leadId,
    pdfElement,
    premiumAllowed,
    pricing,
    promotion,
    setShowModal,
    setShowModalCoupon,
    setShowModalInfo,
    setUpGradedOpen,
    showModal,
    showModalCoupon,
    showModalInfo,
    translate,
    upgraded,
    upGradedOpen,
    validateCoupon,
    downloadableFiles,
  };
};

export default CheckPointController;
