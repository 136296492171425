import * as React from "react";
import { I18nContext } from "../contexts/i18n.context";
import validateForm from "../utils/formValidator";

interface useFormProps {
  fetch: (values: any) => void;
  initialValues?: any;
  setLoading?: (value: boolean) => void;
  requiredFields: any;
  dashBoard?: boolean;
}

const useForm = ({
  fetch,
  initialValues,
  setLoading,
  requiredFields,
  dashBoard = false,
}: useFormProps) => {
  const [values, setValues] = React.useState({ ...initialValues });
  const [error, setError] = React.useState<any[]>([]);

  const {
    state: { translate },
  } = React.useContext(I18nContext);

  const setValue = (name: string, value: any) => {
    if (name in values) {
      setValues({ ...values, [name]: value });
    }
  };

  const resetForm = () => {
    setValues(initialValues);
  };

  const validateSubmitForm = async (): Promise<any[]> => {
    let errorData = [];
    try {
      await validateForm({ values, requiredFields, dashBoard, translate });
    } catch (e: any) {
      setLoading && setLoading(false);
      errorData = JSON.parse(e.message);
    }
    return errorData;
  };

  const handleSubmit = async (): Promise<any> => {
    setLoading && setLoading(true);
    const errorData = await validateSubmitForm();
    setError(errorData);
    if (!errorData.length) {
      return await fetch(values);
    }
  };

  React.useEffect(() => {
    if (values === initialValues) {
      setValues({ ...initialValues });
    }
  }, [values, initialValues]);

  return {
    error,
    handleSubmit,
    resetForm,
    setValue,
    setValues,
    values,
  };
};

export default useForm;
