const numberFormat = (value, type) => {
  if (type === "capital") {
    return new Intl.NumberFormat("es", {
      style: "currency",
      currency: "EUR",
      maximumFractionDigits: 2,
      minimumFractionDigits: 0,
    }).format(Math.round(value));
  } else if (type === "price") {
    return new Intl.NumberFormat("es", {
      style: "currency",
      currency: "EUR",
      maximumFractionDigits: 2,
      minimumFractionDigits: 2,
    }).format(value);
  }
};

export const formatPriceWithoutCurrency = (price) => {
  return Intl.NumberFormat("es", {
    style: "currency",
    currency: "EUR",
    maximumFractionDigits: 2,
    minimumFractionDigits: 2,
  })
    .format(price)
    .replace("€", "")
    .trim();
};

export const numberFormatPayment = (value, type) =>
  new Intl.NumberFormat("de-DE", {
    maximumFractionDigits: 2,
    minimumFractionDigits: type === "capital" ? 0 : 2,
  }).format(value);

export default numberFormat;
